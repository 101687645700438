import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.crmPermissions;

export const selectCrmPermissionsStatuses = createSelector(
  selectState,
  ({ isLoading, error, isInit }) => {
    return { isLoading, error, isInit };
  },
);
export const selectCrmPermissionsData = createSelector(selectState, ({ data }) => {
  return data;
});

export const selectCrmPermissionsOrderBy = createSelector(selectState, ({ orderBy }) => {
  return orderBy;
});
