import { AxiosResponse } from 'axios';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
  select,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IDietaryPreference } from '__generated__/api';
import {
  IGridDietaryPreferenceResponse,
  IGridDietaryPreferencesParams,
  ISourceDietaryPreferencesParams,
} from './models';

const dynamic = dynamicNamespace<IDietaryPreference>();
const REVALIDATE_KEY = RTK_TAGS.DIETARY_PREFERENCES;

export const apiDietaryPreferences = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceDietaryPreferences: build.query({
      queryFn: async ({
        useForPharmAssist,
        useForVitAssist,
      }: Partial<ISourceDietaryPreferencesParams>) => {
        try {
          const res = await API.api.dietaryPreferencesGetAllDynamicList({
            Select: select('id', 'catalogName as title'),
            Filter: mergeFilters(
              dynamic.makeFilter(
                'useForPharmAssist',
                useForPharmAssist,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter(
                'useForVitAssist',
                useForVitAssist,
                decoratorIsNotNullable(equals),
              ),
            ).join('&&'),
            OrderBy: 'catalogName.trim()',
          });

          return rtkAdapterDynamicToSource(res) as AxiosResponse<
            Array<Pick<IDietaryPreference, 'id' | 'catalogName'>>
          >;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.ACTIVE_INGREDIENTS }],
    }),
    getGridDietaryPreferences: build.query<
      IGridDietaryPreferenceResponse,
      IGridDietaryPreferencesParams
    >({
      queryFn: async ({ search, useForPharmAssist, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await API.api.dietaryPreferencesGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName', 'isActive'),
            Filter: mergeFilters(
              dynamic.makeFilter(['catalogName'], search, contains),
              dynamic.makeFilter(
                'useForPharmAssist',
                useForPharmAssist ? true : null,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter(
                'useForVitAssist',
                useForPharmAssist ? null : true,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getDietaryPreference: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.dietaryPreferencesGetAllDynamicList,
            value: id,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postDietaryPreference: build.mutation<IDietaryPreference, IDietaryPreference>({
      queryFn: async (data) => {
        try {
          const result = await API.api.dietaryPreferencesCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchDietaryPreference: build.mutation<void, PatchPartial<IDietaryPreference, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.dietaryPreferencesPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteDietaryPreference: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.dietaryPreferencesDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
