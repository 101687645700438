export const TIME_FORMAT_DATE = 'dd/MM/yyyy';
export const TIME_FORMAT_DATE_TIME = `${TIME_FORMAT_DATE} HH:mm`;
export const TIME_FORMAT_DATE_EXCEL = 'yyyy-MM-dd';

export const IS_DEV = process.env.NODE_ENV === 'development';
export const IS_DEV_BUILD = process.env.REACT_APP_ENV === 'development';

export let PIPELINE_VARIABLES = {
  api: process.env.REACT_APP_API_SERVER as string,
  botBaseUrl: process.env.REACT_APP_BOT_BASE_URL as string,
  botUserProfileID: process.env.REACT_APP_BOT_USER_PROFILE_ID as string,
  botPropertyCode: process.env.REACT_APP_BOT_PROPERTY_CODE as string,
};

export const FilterGridItemBreakPoints = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  xxl: 2.4,
};
