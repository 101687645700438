import { useMemo } from 'react';
import { apiActiveIngredients } from 'services/active-ingredients';
import { apiAppLogTypes } from 'services/app-log-types';
import { apiCases } from 'services/cases';
import { ISourceCasesParams } from 'services/cases/models';
import { apiCountries } from 'services/countries';
import { apiDietaryPreferences } from 'services/dietary-preferences';
import { apiDosageFormCategories } from 'services/dosage-form-categories';
import { apiDosageFormUnitTypes } from 'services/dosage-form-unit-types';
import { apiDosageForms } from 'services/dosage-forms';
import { apiLanguages } from 'services/languages';
import { apiMedicalConditions } from 'services/medical-conditions';
import { ISourceMedicalConditionsParams } from 'services/medical-conditions/models';
import { apiOrderStatuses } from 'services/order-statuses';
import { apiPharmaCompanies } from 'services/pharma-companies';
import { apiProductBrands } from 'services/product-brands';
import { apiProducts } from 'services/products';
import { apiSensitivities } from 'services/sensitivities';
import { ISourceSensitivityParams } from 'services/sensitivities/models';
import { apiTenants } from 'services/tenants';
import { apiUserCrmProfilePermissions } from 'services/user-crm-profile-permissions';
type DataType<T> = T extends { data?: infer U } ? U : unknown;

export const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return useMemo(() => ({ ...v, data }), [v, data]);
};
export const useSourceActiveOny = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []).filter((item) =>
      item.isActive !== undefined ? item.isActive : true,
    ) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return useMemo(() => ({ ...v, data }), [v, data]);
};

export const useSourceLanguages = () => {
  return useSourceData(apiLanguages.useGetAllLanguagesQuery());
};

export const useSourcePharmaCompanies = () => {
  return useSourceData(apiPharmaCompanies.useGetAllPharmaCompaniesQuery());
};

export const useSourceBrands = () => {
  return useSourceData(apiProductBrands.useGetSourceProductBrandsQuery());
};

export const useSourceCountries = () => {
  return useSourceData(apiCountries.useGetSourceCountriesQuery());
};

export const useSourceHighlightCountries = () => {
  return useSourceData(apiCountries.useGetSourceHighlightCountriesQuery());
};

export const useSourceDosageForms = () => {
  return useSourceData(apiDosageForms.useGetSourceDosageFormsQuery());
};

export const useSourceDosageFormCategories = () => {
  return useSourceData(apiDosageFormCategories.useGetSourceDosageFormCategoriesQuery());
};

export const useSourceDosageFormUnitTypes = () => {
  return useSourceData(apiDosageFormUnitTypes.useGetSourceDosageFormUnitTypesQuery());
};

export const useSourceActiveIngredients = () => {
  return useSourceData(apiActiveIngredients.useGetSourceActiveIngredientsQuery());
};

export const useSourceSensitivities = (params: Partial<ISourceSensitivityParams>) => {
  return useSourceData(apiSensitivities.useGetSourceSensitivitiesQuery(params));
};

export const useSourceMedicalConditions = (params: Partial<ISourceMedicalConditionsParams>) => {
  return useSourceData(apiMedicalConditions.useGetSourceMedicalConditionsQuery(params));
};

export const useSourceDietaryPreferences = (params: Partial<ISourceMedicalConditionsParams>) => {
  return useSourceData(apiDietaryPreferences.useGetSourceDietaryPreferencesQuery(params));
};

export const useSourceAppLogTypes = () => {
  return useSourceData(apiAppLogTypes.useGetAllAppLogTypesQuery());
};

export const useSourceTenants = () => {
  return useSourceData(apiTenants.useGetSourceTenantsQuery());
};

export const useSourceCases = (params: Partial<ISourceCasesParams>) => {
  return useSourceData(apiCases.useGetSourceCasesQuery(params));
};

export const useSourceOrderStatuses = () => {
  return useSourceData(apiOrderStatuses.useGetSourceOrderStatusesQuery());
};

export const useSourcePermissions = () => {
  return useSourceData(apiUserCrmProfilePermissions.useGetUserCrmProfilePermissionSourceQuery());
};
export const useSourceFlowEditorProducts = (useForPharmAssist: boolean, skip?: boolean) => {
  return useSourceData(
    apiProducts.useGetSourceFlowEditorProductsQuery({ useForPharmAssist }, { skip }),
  );
};
export const useSourceProductByCompanyID = (
  pharmaCompanyID: string | undefined,
  countryID: string | undefined | null,
) => {
  return useSourceData(
    apiProducts.useGetSourceProductsByCompanyIDQuery(
      { pharmaCompanyID: pharmaCompanyID || '', countryID: countryID || '' },
      {
        skip: !pharmaCompanyID || !countryID,
      },
    ),
  );
};
