import { SourceModel } from 'configs';
import { dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import { API, apiRtk, makePatchArgs, rtkAdapterDynamicToSource, RTK_TAGS } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IUserCrmProfilePermission } from '__generated__/api';

export * from './models';

const REVALIDATE_KEY = RTK_TAGS.USER_CRM_PROFILE_PERMISSIONS;

const dynamic = dynamicNamespace<IUserCrmProfilePermission>();

export const apiUserCrmProfilePermissions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getUserCrmProfilePermissionSource: build.query<IUserCrmProfilePermission[], void>({
      queryFn: async () => {
        try {
          const response = await API.api.userCrmProfilePermissionsGetAllDynamicList({
            Select: dynamic.select('id', 'title'),
            Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
            OrderBy: dynamic.orderBy('title', 'asc'),
          });

          return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
    patchUserCrmProfilePermission: build.mutation<
      void,
      PatchPartial<IUserCrmProfilePermission, 'id'>
    >({
      queryFn: async (data) => {
        try {
          await API.api.userCrmProfilePermissionsPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
