import { ActionFromMatcher } from '@reduxjs/toolkit/dist/tsHelpers';
import { apiUserCrmProfiles } from 'services/user-crm-profiles';
import { actionAccountGetUser } from 'store/auth/actions';
import { selectAccountUser } from 'store/auth/selectors';
import { actionAccountRefresh } from 'store/auth/slice';
import { debounce, put, select, throttle } from 'typed-redux-saga';

function* sagaRefreshAccount() {
  yield* put(actionAccountGetUser({ isOnTheBackground: true }));
}

function* sagaWatchUpdateUser(
  action: ActionFromMatcher<typeof apiUserCrmProfiles.endpoints.patchUserCrmProfile.matchFulfilled>,
) {
  const user = yield* select(selectAccountUser);

  if (!user) return;

  if (user.appIdentityUserID !== action.meta.arg.originalArgs.dataOld?.appIdentityUserID) return;

  yield* put(actionAccountRefresh());
}

export const sagasAccounts = [
  debounce(3000, actionAccountRefresh, sagaRefreshAccount),
  throttle(
    3000,
    apiUserCrmProfiles.endpoints.patchUserCrmProfile.matchFulfilled,
    sagaWatchUpdateUser,
  ),
];
