import { BaseQueryFn } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig, AxiosResponse, AxiosResponseTransformer } from 'axios';
import { format, isDate } from 'date-fns';
import { DynamicResult } from 'modules/dynamic-service';
import { Kill } from 'utils/types';
import { Api } from '__generated__/api';
import { equals } from './dynamic';

export const isServerDateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}?((\.\d+)|$)/gi.test(String(value));
};
export const isISODateString = <T>(value: T) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d+Z$/gi.test(String(value));
};

export const axiosDateTransformer: AxiosResponseTransformer = (res) => {
  try {
    return JSON.parse(res, (key, value) => {
      // if (typeof value === 'string' && isServerDateString(value)) {
      //   const [_dateString, _ms] = value.split('.');
      //   const _dateNumber = Date.parse(_dateString);
      //
      //   if (_dateNumber) {
      //     const ms = _ms || '000';
      //     // ms has no timezone | only numbers
      //     if (/^[0-9]+$/gi.test(ms)) {
      //       return `${_dateString}.${ms}Z`;
      //     }
      //   }
      // }
      return value;
    });
  } catch (e) {
    return res;
  }
};

export const API = new Api();
export const apiApp = API.instance;
apiApp.defaults.transformResponse = [axiosDateTransformer];
export const APIFree = new Api();
export const apiFree = APIFree.instance;
apiFree.defaults.transformResponse = [axiosDateTransformer];

export const axiosBaseQuery =
  (): BaseQueryFn<AxiosRequestConfig, unknown, AxiosError | Error> => async (config) => {
    try {
      const result = await apiApp({
        method: 'get',
        ...config,
      });
      return { data: result.data };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: err,
      };
    }
  };

export const normalizeForm = <T extends Record<string, any>>(data: T): Kill<T> => {
  return Object.keys(data).reduce(
    (acc, key) => {
      if (acc[key] === null) {
        // @ts-ignore
        acc[key] = '';
      }
      return acc;
    },
    { ...data },
  );
};
export enum RTK_TAGS {
  LABELS = 'labels',
  LANGUAGES = 'languages',
  CASES = 'cases',
  CASES_CATEGORIES = 'cases-categories',
  CASES_CATEGORY_CONTENTS = 'case-category-contents',
  CASES_PAGE_TYPES = 'cases-page-types',
  CASES_PAGES = 'cases-pages',
  CASES_PAGE_CONTENTS = 'cases-page-contents',
  CASES_DECISIONS = 'cases-decisions',
  CASES_DECISION_CONTENTS = 'cases-decisions-contents',
  CASES_SENSITIVITIES = 'cases-sensitivities',
  CASES_MEDICAL_CONDITIONS = 'cases-medical-conditions',
  CASES_MEDICAL_CONDITION_GROUPS = 'cases-medical-condition-groups',
  CASES_MEDICAL_CONDITION_GROUPS_CONTENTS = 'cases-medical-condition-group-contents',
  CASES_DIETARY_PREFERENCES = 'cases-dietary-preferences',
  CASES_DOSAGE_FORMS = 'cases-dosage-forms',
  RED_FLAG_PAGE = 'red-flag-page',
  RED_FLAG_OPTIONS = 'red-flag-options',
  RED_FLAG_OPTION_CONTENTS = 'red-flag-option-contents',
  RED_FLAG_PAGE_CONTENTS = 'red-flag-page-contents',
  RED_FLAG = 'red-flag',
  RED_FLAG_CONTENTS = 'red-flag-contents',
  GENDERS = 'genders',
  GENDER_CONTENTS = 'gender-contents',
  COUNTRIES = 'countries',
  USER_CRM_PROFILE_PERMISSIONS = 'user-crm-profile-permissions',
  REDIS_PARAMS = 'redis-params',
  APP_LOGS = 'app-logs',
  USER_CRM_PROFILE = 'user-crm-profile',
  COUNTRY_LANGUAGES = 'country-languages',
  SENSITIVITIES = 'sensitivities',
  SENSITIVITY_CONTENTS = 'sensitivity-contents',
  ACTIVE_INGREDIENTS = 'active-ingredients',
  DIETARY_PREFERENCES = 'dietary-preferences',
  DIETARY_PREFERENCE_CONTENTS = 'dietary-preference-contents',
  MEDICAL_CONDITIONS = 'medical-conditions',
  MEDICAL_CONDITION_CONTENTS = 'medical-condition-contents',
  MEDICAL_CONDITION_INGREDIENTS = 'medical-condition-ingredients',
  MEDICAL_CONDITION_INGREDIENT_CONTENTS = 'medical-condition-ingredient-contents',
  DOSAGE_FORM_CATEGORIES = 'dosage-form-categories',
  DOSAGE_FORM_CATEGORY_CONTENTS = 'dosage-form-category-contents',
  DOSAGE_FORMS = 'dosage-forms',
  DOSAGE_FORMS_CONTENTS = 'dosage-forms-contents',
  DOSAGE_FORM_UNIT_TYPE = 'dosage-form-unit-type',
  DOSAGE_FORM_UNIT_TYPE_CONTENTS = 'dosage-form-unit-type-contents',
  ORDER_STATUSES = 'order-statuses',
  PHARMA_COMPANY_ORDERS = 'pharma-company-orders',
  PHARMA_COMPANY_ORDER_DETAILS = 'pharma-company-order-details',
  PRODUCT_BRANDS = 'product-brands',
  PRODUCT_BRANDS_CONTENTS = 'product-brands-contents',
  PRODUCTS = 'products',
  PRODUCT_CASES = 'product-cases',
  PRODUCT_SENSITIVITIES = 'product-sensitivities',
  PRODUCT_MEDICATIONS = 'product-medications',
  PRODUCT_LEAFLETS = 'product-leaflets',
  PRODUCT_MEDICAL_CONDITIONS = 'product-medical-conditions',
  PRODUCT_DIETARY_PREFERENCES = 'product-dietary-preferences',
  PHARMA_COMPANIES = 'pharma-companies',
  TENANTS = 'tenants',
  TENANT_USERS = 'tenant-users',
  CASE_TAGS = 'case-tags',
  CASE_CONTENTS = 'case-contents',
}
export const apiRtk = createApi({
  reducerPath: 'apiRtk',
  baseQuery: axiosBaseQuery(),
  tagTypes: Object.values(RTK_TAGS),
  endpoints: () => ({}),
});

export const parseErrorData = <T = unknown>(error: AxiosError<T> | Partial<Error>) => {
  if (!error) {
    return new Error('error');
  }
  if ('isAxiosError' in error) {
    const errorData = error.response?.data;

    if (!errorData) {
      return new Error('error');
    }

    if (typeof errorData === 'string') {
      return new Error(errorData);
    }

    return new Error((errorData as any)?.title || (errorData as any)?.message || error.message);
  }
  return new Error(error.message);
};

export const isRejectedMutation = <T>(mutationResult: any): mutationResult is { error: T } => {
  return Boolean(mutationResult && mutationResult.error);
};
export const isFulfilledMutation = <T>(mutationResult: any): mutationResult is { data: T } => {
  return !isRejectedMutation(mutationResult);
};

export const transformResponseDynamic = <T>(data: { value: T[] }) => {
  return data.value;
};

export const calcPaginationSkip = ({ page, take }: { take: number; page: number }) => {
  return take * (page - 1);
};
export const calcPaginationState = ({
  take,
  page,
  count,
}: {
  take: number;
  count: number;
  page: number;
}) => {
  const skip = calcPaginationSkip({ take, page });
  const pages = Math.ceil(count / take);
  const isLastPage = pages === page;
  const isFirstPage = page === 1;
  return {
    take,
    page,
    count,
    pages,
    skip,
    isFirstPage,
    isLastPage,
  };
};

const prepareRequestDateValue = (value: any) => {
  if (!value) return value;
  if (isISODateString(value)) {
    return format(new Date(value), 'yyyy-MM-dd') + 'T' + format(new Date(value), 'HH:mm:ss');
  }
  if (isDate(value)) {
    return format(new Date(value), 'yyyy-MM-dd') + 'T' + format(new Date(value), 'HH:mm:ss');
  }

  return value;
};

const prepareFieldWithID = (value: any, key: any) => {
  return value === '' && String(key).endsWith('ID') ? null : value;
};

export const prepareRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  const keys = Object.keys(data) as (keyof T)[];
  return keys.reduce((acc, key) => {
    const value = acc[key];
    acc[key] = prepareFieldWithID(prepareRequestDateValue(value), key);
    return acc;
  }, data);
};
export const preparePatchRequestData = <T extends { [x: string]: any | null } = {}>(data: T) => {
  return Object.entries(prepareRequestData(data))
    .filter(([, value]) => value !== undefined)
    .map(([path, value]) => {
      return { path, value: prepareRequestDateValue(value) };
    });
};

export const makePatchArgs = <
  T extends Record<string, any> = { id: string },
  K extends keyof T = 'id',
>(
  data: T,
  field: K = 'id' as K,
) => {
  const { [field]: id, ...rest } = data;
  return [String(id), preparePatchRequestData(rest)] as const;
};

export const getByIDRequestHelper = async <M extends Record<string, any>>({
  apiFunc,
  value,
  params = {},
  field = 'id' as keyof M,
}: {
  apiFunc: (...args: any[]) => Promise<
    AxiosResponse<{
      items: M[];
    }>
  >;
  value: M[keyof M];
  params?: {};
  field?: keyof M;
}) => {
  const response = await apiFunc({ Filter: equals(String(field), value), Take: 1, ...params });
  const data = response.data.items[0] as M;
  if (!data) {
    throw new Error('record-not-found');
  } else {
    return { ...response, data };
  }
};

export const rtkAdapterDynamicToSource = <Result>(
  response: AxiosResponse<DynamicResult<Result>>,
) => {
  return { ...response, data: response.data.items } as AxiosResponse<Result[]>;
};
export const rtkAdapterDynamicItem = <Result>(response: AxiosResponse<DynamicResult<Result>>) => {
  const data = response.data.items[0];

  if (!data) {
    throw new Error('record-not-found');
  }
  return { ...response, data } as AxiosResponse<Result>;
};

export const rtkAdapterDynamicCount = <Result>(response: AxiosResponse<DynamicResult<Result>>) => {
  const data = response.data.count;

  return { ...response, data } as AxiosResponse<Result>;
};

export const rtkAdapterError = (response: AxiosError) => {
  return { error: response };
};

interface MoveRowsOptions<T extends Record<string, any>> {
  mainField: keyof T;
  moveField: keyof T;
  requestPatch: (data: Partial<T>) => void;
  newRows: Partial<T>[];
  oldRows: Partial<T>[];
}
export const behaviourMoveRows = async <T extends Record<string, any>>(
  options: MoveRowsOptions<T>,
) => {
  const { newRows, oldRows, requestPatch } = options;
  return Promise.all(
    newRows.map((oldRecord, i) => {
      return requestPatch({
        [options.mainField]: oldRecord[options.mainField],
        [options.moveField]: oldRows[i][options.moveField],
      } as Partial<T>);
    }),
  );
};
