import { useTranslate } from 'hooks/use-translate';
import { useCallback } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { fieldToLabelKey } from 'utils/other';
import { TranslateMap } from '../configs';

export type FieldRenderProps = {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
};
export const useFieldProps = <T extends Record<string, any>>(sharedProps?: T) => {
  const { t, tp } = useTranslate();

  return useCallback(
    (renderProps: FieldRenderProps, label?: keyof TranslateMap) => {
      const { field, fieldState } = renderProps;
      const error = fieldState.error;
      return {
        ...(sharedProps as Required<T>),
        ...field,
        label: label ? tp(label) : t(fieldToLabelKey(field.name)),
        error: Boolean(error),
        helperText: error && error.message ? t(error.message) : ' ',
      };
    },
    [t, tp, sharedProps],
  );
};
