import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';
import { defaultCasePagesGridFilters } from './slice';

const selectState = (state: AppState) => state.casePages;
export const selectCasePagesGridFilters = createSelector(selectState, ({ gridFilters }) => {
  return gridFilters;
});
export const selectCasePagesGridHasFilters = createSelector(
  selectCasePagesGridFilters,
  (gridFilters) => {
    return JSON.stringify(gridFilters) !== JSON.stringify(defaultCasePagesGridFilters);
  },
);
export const selectCasePagesGridOrder = createSelector(selectState, ({ gridOrder }) => {
  return gridOrder;
});
export const selectCasePagesGridPagination = createSelector(selectState, ({ gridPagination }) => {
  return gridPagination;
});
