import { equals, makeFilter, orderBy, select } from 'utils/dynamic';
import { API, apiRtk, rtkAdapterDynamicToSource, RTK_TAGS } from 'utils/service';
import { ICasePageType } from '__generated__/api';

export enum CASE_PAGE_TYPE {
  RedFlag = 1,
  DosageForm,
  Symptom,
  Sensitivity,
  Message,
  MedicalCondition,
  Decision,
  Start,
  Stop,
  End,
  ComputeMedicalCondition,
  MedicalConditionGroup,
  DietaryPreference,
}
export interface IAppCasePageType extends Omit<ICasePageType, 'cpType'> {
  cpType: CASE_PAGE_TYPE;
}

export const apiCasePageTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceCasePageTypes: build.query({
      queryFn: async (arg: void) => {
        try {
          const res = await API.api.casePageTypesGetAllDynamicList({
            Select: select('id', 'title', 'cpType', 'isActive'),
            Filter: makeFilter('isActive', true, equals),
            OrderBy: orderBy('title', 'asc'),
          });

          return rtkAdapterDynamicToSource<
            Pick<IAppCasePageType, 'id' | 'isActive' | 'title' | 'cpType'>
          >(res as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.CASES_PAGE_TYPES }],
    }),
  }),
});
