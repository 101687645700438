import { PageFooter } from 'components/page-footer';
import { PageHeader } from 'components/page-header';
import { PageLayout } from 'components/page-layout';
import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';
import style from './index.module.scss';

export const LabelsLayout = memo(() => {
  const { tp } = useTranslate();
  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.SETTINGS_LABELS.path,
        to: APP_ROUTES.SETTINGS_LABELS().config,
        label: tp('labels-crm'),
      },
      {
        value: APP_ROUTES.SETTINGS_LABELS_FOR_BOT.path,
        to: APP_ROUTES.SETTINGS_LABELS_FOR_BOT().config,
        label: tp('labels-bot'),
      },
      {
        value: APP_ROUTES.SETTINGS_LABELS_FOR_PLATFORM.path,
        to: APP_ROUTES.SETTINGS_LABELS_FOR_PLATFORM().config,
        label: tp('labels-platform'),
      },
      {
        value: APP_ROUTES.SETTINGS_LABELS_FOR_PORTAL.path,
        to: APP_ROUTES.SETTINGS_LABELS_FOR_PORTAL().config,
        label: tp('labels-portal'),
      },
      {
        value: APP_ROUTES.SETTINGS_LABELS_FOR_WEBSITE.path,
        to: APP_ROUTES.SETTINGS_LABELS_FOR_WEBSITE().config,
        label: tp('labels-website'),
      },
    ];
  }, [tp]);
  return (
    <PageLayout className={style.root}>
      <PageHeader title={tp('labels')} />
      <TabsPage
        tabs={tabs}
        className={style.tabs}
        classes={{
          root: style.tabs,
          wrap: style.tabsWrap,
        }}
      />
      <PageFooter />
    </PageLayout>
  );
});
