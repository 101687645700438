import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  DynamicOrder,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { API, apiRtk, getByIDRequestHelper, makePatchArgs, RTK_TAGS } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ILabel } from '__generated__/api';
import { IGridLabelResponse, LABEL_SOURCE_ID } from './models';

const REVALIDATE_KEY = RTK_TAGS.LABELS;

const dynamic = dynamicNamespace<ILabel>();

export * from './models';

export interface IGridLabelParams {
  languageID: string;
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  labelSourceID: string;
  isActive: boolean | null;
}

export const apiLabels = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAppLabels: build.query<ILabel[], { languageID: string }>({
      queryFn: async ({ languageID }) => {
        try {
          const {
            data: { items },
          } = await API.api.labelsGetAllDynamicList({
            Select: dynamic.select('id', 'labelKey', 'title'),
            Filter: mergeFilters(
              dynamic.makeFilter('languageID', languageID, equals),
              dynamic.makeFilter('labelSourceID', LABEL_SOURCE_ID.CRM, equals),
              dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
          });
          return { data: items };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getGridLabels: build.query<IGridLabelResponse, IGridLabelParams>({
      // @ts-ignore
      queryFn: async ({
        languageID,
        search,
        take: Take,
        skip: Skip,
        orderBy,
        labelSourceID,
        isActive,
      }) => {
        try {
          const result = await API.api.labelsGetAllDynamicList({
            Select: dynamic.select(
              'id',
              'labelKey',
              'title',
              'isActive',
              'language.title as languageTitle',
              'labelSourceID',
            ),
            Filter: mergeFilters(
              dynamic.makeFilter(['title', 'labelKey'], search, contains),
              dynamic.makeFilter('languageID', languageID, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('labelSourceID', labelSourceID, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getLabel: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.labelsGetAllDynamicList,
            value: id,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postLabel: build.mutation<ILabel, ILabel>({
      queryFn: async (data) => {
        try {
          const result = await API.api.labelsCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchLabel: build.mutation<void, PatchPartial<ILabel, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.labelsPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteLabel: build.mutation<void, PatchPartial<ILabel, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.labelsDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    syncLabels: build.query({
      queryFn: async (languageID: string) => {
        try {
          const result = await API.api.labelsSyncDetail(languageID);
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
  }),
});
