import { createAsyncThunk } from '@reduxjs/toolkit';
import { ICaseDecision, ICasePage } from '__generated__/api';
import dagre from 'dagre';
import { CASE_PAGE_TYPE } from 'services/case-page-types';
import * as dynamic from 'utils/dynamic';
import { API, makePatchArgs, parseErrorData, rtkAdapterDynamicToSource } from 'utils/service';
import { FixServerObject, Leave, PickServerObject } from 'utils/types';
import { AppAsyncThunkConfig } from '../index';
import { selectFlowEditorCaseID, selectFlowEditorEdges, selectFlowEditorNodes } from './selectors';

type CasePageData = Pick<ICasePage, 'id' | 'catalogName' | 'x' | 'y' | 'nextCasePageID'> & {
  type: CASE_PAGE_TYPE;
  typeTitle: string;
};

export type FlowCaseDecision = Pick<
  ICaseDecision,
  'id' | 'catalogName' | 'nextCasePageID' | 'orderNumber'
>;

export type FlowCasePage = CasePageData & {
  caseDecisions: FlowCaseDecision[];
};

export type FlowNodeData = { casePageData: FlowCasePage; isLoading: boolean; isUpdating: boolean };

export const actionFlowEditorInit = createAsyncThunk<FlowCasePage[], string, AppAsyncThunkConfig>(
  'FLOW_EDITOR/init',
  async (caseID) => {
    try {
      const result = await API.api.casePagesGetAllDynamicList({
        Filter: dynamic.makeFilter('caseID', caseID, dynamic.equals),
        Select: dynamic.select(
          'id',
          'x',
          'y',
          'catalogName',
          'nextCasePageID',
          'casePageType.cpType as type',
          'casePageType.title as typeTitle',
          `caseDecisions.OrderBy(d => d.orderNumber).Select(d =>
          new {
            d.id,
            d.catalogName,
            d.nextCasePageID,
            d.orderNumber
          }
         ) as caseDecisions
        `,
        ),
      });

      const { data } = rtkAdapterDynamicToSource<FlowCasePage>(result as unknown as any);

      return data;
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);

type TFlowEditorAddInput = PickServerObject<
  ICasePage,
  'caseID' | 'casePageTypeID' | 'x' | 'y' | 'catalogName'
> & {
  fakeID: string;
  cpType: CASE_PAGE_TYPE;
};
export const actionFlowEditorAddNode = createAsyncThunk<
  ICasePage,
  TFlowEditorAddInput,
  AppAsyncThunkConfig
>('FLOW_EDITOR/addCasePageNode', async (data, { dispatch }) => {
  try {
    const { data: result } = await API.api.casePagesCreateCreate({
      caseID: data.caseID,
      casePageTypeID: data.casePageTypeID,
      catalogName: data.catalogName,
      x: data.x,
      y: data.y,
    });

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionFlowEditorPerformRemoveNodes = createAsyncThunk<
  void,
  string[],
  AppAsyncThunkConfig
>('FLOW_EDITOR/performRemoveNodes', async (nodeIDs, { dispatch, getState }) => {
  try {
    const nodes = selectFlowEditorNodes(getState());

    const leftNodes = nodes.filter((node) => {
      return !nodeIDs.some((id) => id === node.id);
    });

    const casePagesToUpdate = leftNodes
      .filter((node) => {
        return nodeIDs.some((id) => id === node.data.casePageData.nextCasePageID);
      })
      .map((node) => node.data.casePageData);

    const decisionsToUpdate = leftNodes
      .map((node) =>
        node.data.casePageData.caseDecisions.map((decision) => {
          return { ...decision, casePageID: node.data.casePageData.id };
        }),
      )
      .flat()
      .filter((decision) => {
        return nodeIDs.some((id) => id === decision.nextCasePageID);
      });

    await Promise.all([
      ...casePagesToUpdate.map((casePage) =>
        dispatch(
          actionFlowEditorUpdateNode({
            id: String(casePage.id),
            nextCasePageID: null,
          }),
        ),
      ),
      ...decisionsToUpdate.map((decision) =>
        dispatch(
          actionFlowEditorUpdateNodeDecision({
            id: String(decision.id),
            casePageID: String(decision.casePageID),
            nextCasePageID: null,
          }),
        ),
      ),
    ]);

    const removeItemsPromises = nodeIDs.map((id) => API.api.casePagesDeleteDelete(id));

    await Promise.all(removeItemsPromises);
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionFlowEditorUpdateNode = createAsyncThunk<
  void,
  Leave<FixServerObject<ICasePage, 'id'>>,
  AppAsyncThunkConfig
>('FLOW_EDITOR/updateNode', async (data) => {
  try {
    await API.api.casePagesPatchPartialUpdate(...makePatchArgs(data));
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionFlowEditorUpdateNodeDecision = createAsyncThunk<
  void,
  Leave<FixServerObject<ICaseDecision, 'id' | 'casePageID'>>,
  AppAsyncThunkConfig
>('FLOW_EDITOR/updateNodeDecision', async (data) => {
  try {
    await API.api.caseDecisionsPatchPartialUpdate(...makePatchArgs(data));
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionFlowEditorConnectNodes = createAsyncThunk<
  void,
  { casePageID: string; nextCasePageID: string; caseDecisionID: string | null },
  AppAsyncThunkConfig
>('FLOW_EDITOR/connectNodes', async (data, { dispatch }) => {
  try {
    const { casePageID, nextCasePageID, caseDecisionID } = data;

    // if decision update the decision
    if (caseDecisionID) {
      await API.api.caseDecisionsPatchPartialUpdate(
        ...makePatchArgs({ id: caseDecisionID, nextCasePageID }),
      );
    } else {
      await API.api.casePagesPatchPartialUpdate(
        ...makePatchArgs({ id: casePageID, nextCasePageID }),
      );
    }
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionFlowEditorAutoAlign = createAsyncThunk<void, void, AppAsyncThunkConfig>(
  'FLOW_EDITOR/autoAlign',
  async (_, { getState, dispatch }) => {
    try {
      const startedCaseID = selectFlowEditorCaseID(getState());

      if (!startedCaseID) {
        return;
      }

      const nodes = selectFlowEditorNodes(getState());
      const edges = selectFlowEditorEdges(getState());
      const dagreGraph = new dagre.graphlib.Graph();
      dagreGraph.setDefaultEdgeLabel(() => ({}));

      dagreGraph.setGraph({ rankdir: 'TB' });

      nodes.forEach((node) => {
        dagreGraph.setNode(node.id, {
          width: node.width,
          height: node.height,
        });
      });

      edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
      });

      dagre.layout(dagreGraph);

      await Promise.all(
        nodes.map((node) => {
          const alignedNode = dagreGraph.node(node.id);
          const payload = { id: node.id, x: alignedNode.x, y: alignedNode.y };
          return API.api.casePagesPatchPartialUpdate(...makePatchArgs(payload));
        }),
      );

      const currentID = selectFlowEditorCaseID(getState());

      if (startedCaseID === currentID) {
        await dispatch(actionFlowEditorInit(currentID));
      }
    } catch (e: any) {
      throw parseErrorData(e);
    }
  },
);
