import React from 'react';

import { Menu, MenuOpen } from '@mui/icons-material';
import { Box, IconButton, Stack, StackProps, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';
import { ReactComponent as Logo } from 'images/logo.svg';
import style from './index.module.scss';

interface Props extends Omit<StackProps, 'classes'> {
  isOpen: boolean;
  onToggle: () => void;
}

export const MenuLogo: React.FC<Props> = ({ isOpen, onToggle, children, className, ...rest }) => {
  const { tp } = useTranslate();
  return (
    <Stack direction={'row'} {...rest} className={clsx(style.root)}>
      <Box className={clsx(style.icon)}>
        <IconButton color={'secondary'} onClick={onToggle}>
          {isOpen ? <MenuOpen /> : <Menu />}
        </IconButton>
      </Box>
      <Stack
        direction={'row'}
        alignSelf={'center'}
        ml={'-2rem'}
        flex={'0 0 18rem'}
        className={clsx(style.box, isOpen && style.boxOpen)}
      >
        <Logo style={{ width: '6rem' }} />
        <Typography variant={'body2'} alignSelf={'flex-end'} pb={'0.4rem'} pl={'0.4rem'}>
          {tp('apo-pro')}
        </Typography>
      </Stack>
    </Stack>
  );
};
