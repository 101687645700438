import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserCrmProfilePermissions,
  IGridCrmPermissionsResponse,
} from 'services/user-crm-profile-permissions';
import { dynamicNamespace } from 'utils/dynamic';
import { API, parseErrorData } from 'utils/service';
import { IUserCrmProfilePermission } from '__generated__/api';
import { AppAsyncThunkConfig } from '../index';
import { selectCrmPermissionsOrderBy } from './selectors';

const dynamic = dynamicNamespace<IUserCrmProfilePermission>();

export const actionCrmPermissionsGetAllDynamic = createAsyncThunk<
  IGridCrmPermissionsResponse,
  void,
  AppAsyncThunkConfig
>(`CRM_PERMISSIONS/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { field, order } = selectCrmPermissionsOrderBy(getState());
    const params = {
      Select: dynamic.select(
        'id',
        'title',
        'isAllowToEditWebsites',
        'isAllowToEditSetting',
        'isAllowToEditTenants',
        'isAllowToEditFlows',
        'isAllowToEditLabels',
        'isAllowToEditProfile',
        'isAllowToEditCRMUsers',
        'isAllowToEditPermissions',
        'isAllowToViewLog',
        'isAllowToViewSensitiveInformation',
        'isAllowToResetSensitiveInformation',
        'isActive',
      ),
      OrderBy: dynamic.orderBy(field, order),
    };
    const { data: result } = await API.api.userCrmProfilePermissionsGetAllDynamicList(params);

    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
export const actionCrmPermissionsChangeItem = createAsyncThunk<
  void,
  Partial<IUserCrmProfilePermission>,
  AppAsyncThunkConfig
>(`CRM_PERMISSIONS/changeItem`, async (data, { dispatch }) => {
  try {
    const id = data.id;
    if (!id) {
      throw new Error('id is required');
    }
    await dispatch(
      apiUserCrmProfilePermissions.endpoints.patchUserCrmProfilePermission.initiate({
        ...data,
        id,
      }),
    );
    await dispatch(actionCrmPermissionsGetAllDynamic()).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
