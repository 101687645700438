import { AxiosResponse } from 'axios';
import { SourceModel } from 'configs';
import * as dynamic from 'utils/dynamic';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicCount,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ICase, ICloneCaseRequest } from '__generated__/api';
import {
  ICasesContentResponse,
  IGeneralCaseInput,
  IGridPageCaseResponse,
  IGridPageInput,
  IGridScenarioResponse,
  ISourceCasesParams,
} from './models';

const dynamicCase = dynamicNamespace<ICase>();

export const apiCases = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridPageCases: build.query<IGridPageCaseResponse, IGridPageInput>({
      queryFn: async (input) => {
        try {
          const result = await API.api.casesGetAllDynamicList({
            Filter: mergeFilters(
              dynamicCase.makeFilter(
                ['catalogName', 'caseCategory.catalogName'],
                input.search,
                contains,
              ),
              dynamicCase.makeFilter(
                'caseCategoryID',
                input.caseCategoryID,
                decoratorIsNotNullable(equals),
              ),
              dynamicCase.makeFilter('countryID', input.countryID, decoratorIsNotNullable(equals)),
              dynamicCase.makeFilter('genderID', input.genderID, decoratorIsNotNullable(equals)),
            ).join('&&'),
            Select: dynamicCase.select(
              'id',
              'countryID',
              'catalogName',
              'caseCategory.catalogName as caseCategoryTitle',
              'fromAge',
              'toAge',
              'gender.catalogName as genderTitle',
              'new { country.countryName, country.flag } as country',
              'isActive',
            ),
            Take: input.take,
            Skip: input.skip,
            OrderBy: dynamicCase.orderBy(input.orderBy),
            Count: true,
          });

          return result as AxiosResponse<IGridPageCaseResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.CASES }],
    }),
    getGeneralCaseByID: build.query<IGeneralCaseInput, string>({
      queryFn: async (id) => {
        try {
          const result = await API.api.casesGetAllDynamicList({
            Filter: mergeFilters(
              dynamicCase.makeFilter('id', id, decoratorIsNotNullable(equals)),
            ).join('&&'),
            Select: dynamicCase.select(
              'id',
              'catalogName',
              'caseCategoryID',
              'genderID',
              'countryID',
              'fromAge',
              'toAge',
              'isActive',
              'useForPharmAssist',
              'useForVitAssist',
            ),
            Take: 1,
          });

          return rtkAdapterDynamicItem(result);
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: RTK_TAGS.CASES, id }],
    }),
    patchCase: build.mutation<void, PatchPartial<ICase, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.casesPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: RTK_TAGS.CASES },
        { type: RTK_TAGS.CASES, id: String(data?.id) },
      ],
    }),
    postCase: build.mutation<ICase, Omit<ICase, 'id'>>({
      queryFn: async (data) => {
        try {
          const result = await API.api.casesCreateCreate(data);
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.CASES }],
    }),
    cloneCase: build.mutation<void, ICloneCaseRequest>({
      queryFn: async (data) => {
        try {
          const result = await API.api.casesCloneCreate(data);
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.CASES }],
    }),
    deleteCase: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.casesDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: RTK_TAGS.CASES },
        { type: RTK_TAGS.CASES, id: String(data?.id) },
      ],
    }),
    getSourceCases: build.query({
      queryFn: async ({
        useForPharmAssist,
        useForVitAssist,
        countryID,
      }: Partial<ISourceCasesParams>) => {
        try {
          const response = await API.api.casesGetAllDynamicList({
            Select: dynamicCase.select('id', 'catalogName as title', 'isActive'),
            Filter: mergeFilters(
              dynamicCase.makeFilter(
                'useForPharmAssist',
                useForPharmAssist,
                decoratorIsNotNullable(equals),
              ),
              dynamicCase.makeFilter(
                'useForVitAssist',
                useForVitAssist,
                decoratorIsNotNullable(equals),
              ),
              dynamicCase.makeFilter('countryID', countryID, decoratorIsNotNullable(equals)),
            ).join('&&'),
          });
          return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getCasesByCountryCount: build.query<number, { countryID: string }>({
      queryFn: async ({ countryID }) => {
        try {
          const result = await API.api.casesGetAllDynamicList({
            Filter: mergeFilters(
              dynamicCase.makeFilter('countryID', countryID, decoratorIsNotNullable(equals)),
              dynamicCase.makeFilter('isActive', true, equals),
            ).join('&&'),
            Select: dynamicCase.select('id'),
            Count: true,
          });

          return rtkAdapterDynamicCount(result as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getGridScenarios: build.query<IGridScenarioResponse, { countryID: string }>({
      queryFn: async ({ countryID }) => {
        try {
          const result = await API.api.casesGetAllDynamicList({
            Select: dynamicCase.select(
              'id',
              'catalogName',
              'caseCategory.catalogName as caseCategoryTitle',
              // 'new { country.countryName, country.flag, country.id } as country',
            ),
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('countryID', countryID, dynamic.equals),
                dynamic.makeFilter('isActive', true, equals),
              )
              .join('&&'),
            Count: true,
          });

          return result as AxiosResponse<IGridPageCaseResponse>;
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getCasesContent: build.query<ICasesContentResponse, { caseID: string }>({
      queryFn: async ({ caseID }) => {
        try {
          const result = await API.api.casesGetContentList({
            caseID,
          });
          return {
            ...result,
            data: { items: result.data },
          } as unknown as AxiosResponse<ICasesContentResponse>;
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});
