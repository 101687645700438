import { REGEXP_CATALOG_NAME, REGEXP_EMAIL, REGEXP_HEX } from 'utils/regexp';
import * as yup from 'yup';

yup.addMethod<yup.StringSchema>(yup.string, 'colorHex', function (message: string) {
  return this.test('color-hex', message, function (value, message) {
    const { path, createError } = this;
    if (!value) return true;
    if (!new RegExp(REGEXP_HEX).test(String(value))) {
      return createError({ path, message });
    }
    return true;
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'emailFixed', function (message: string) {
  return this.test('email-fixed', message, function (value) {
    const { path, createError } = this;
    if (!value) return true;
    if (!new RegExp(REGEXP_EMAIL).test(String(value))) {
      return createError({ path, message });
    }
    return true;
  });
});

yup.addMethod<yup.StringSchema>(yup.string, 'catalogName', function (message: string) {
  return this.test('catalog-name', message, function (value) {
    const { path, createError } = this;

    if (!value) return true;
    if (!new RegExp(REGEXP_CATALOG_NAME).test(String(value))) {
      return createError({ path, message });
    }
    return true;
  });
});

export * from 'yup';
export default yup;
