import { AxiosResponse } from 'axios';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
  select,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IMedication } from '__generated__/api';
import { IGridActiveIngredientsParams, IGridActiveIngredientsResponse } from './models';

const dynamic = dynamicNamespace<IMedication>();
const REVALIDATE_KEY = RTK_TAGS.ACTIVE_INGREDIENTS;

export const apiActiveIngredients = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceActiveIngredients: build.query({
      queryFn: async (arg: void) => {
        try {
          const res = await API.api.medicationsGetAllDynamicList({
            Select: select('id', 'catalogName as title', 'isActive'),
            OrderBy: 'catalogName.trim()',
          });

          return rtkAdapterDynamicToSource(res) as AxiosResponse<
            Array<Pick<IMedication, 'id' | 'catalogName'>>
          >;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.ACTIVE_INGREDIENTS }],
    }),
    getGridActiveIngredients: build.query<
      IGridActiveIngredientsResponse,
      IGridActiveIngredientsParams
    >({
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await API.api.medicationsGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName', 'isActive'),
            Filter: mergeFilters(
              dynamic.makeFilter(['catalogName'], search, contains),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getActiveIngredient: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.medicationsGetAllDynamicList,
            value: id,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: RTK_TAGS.ACTIVE_INGREDIENTS, id }],
    }),
    postActiveIngredient: build.mutation<IMedication, IMedication>({
      queryFn: async (data) => {
        try {
          const result = await API.api.medicationsCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchActiveIngredient: build.mutation<void, PatchPartial<IMedication, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.medicationsPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteActiveIngredient: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.medicationsDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
