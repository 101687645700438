import { AxiosResponse } from 'axios';
import * as dynamic from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { FixServerObject } from 'utils/types';
import { ICasePage } from '__generated__/api';
import { CASE_PAGE_TYPE } from '../case-page-types';
import { CasePageNavigation, GetCasePageFormResponse } from './models';

export const apiCasePages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getCasePageAutocomplete: build.query<
      Pick<ICasePage, 'catalogName'>[],
      { cpType: CASE_PAGE_TYPE; countryID: string }
    >({
      queryFn: async ({ cpType, countryID }) => {
        try {
          const res = await API.api.casePagesGetAllDynamicList({
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('casePageType.cpType', cpType, dynamic.equals),
                dynamic.makeFilter('case.countryID', countryID, dynamic.equals),
              )
              .join('&&'),
            Select: dynamic.select('id', 'catalogName'),
          });
          return rtkAdapterDynamicToSource(res);
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getCasePageAutocompleteNavigation: build.query<
      CasePageNavigation[],
      { cpType: CASE_PAGE_TYPE; countryID: string; partCatalogName: string }
    >({
      queryFn: async ({ cpType, countryID, partCatalogName }) => {
        try {
          const res = await API.api.casePagesGetAllDynamicList({
            Filter: dynamic
              .mergeFilters(
                dynamic.makeFilter('casePageType.cpType', cpType, dynamic.equals),
                dynamic.makeFilter('case.countryID', countryID, dynamic.equals),
                dynamic.makeFilter('catalogName', partCatalogName, dynamic.contains),
                dynamic.makeFilter('catalogName', partCatalogName, dynamic.contains),
                'casePageContents.Count() > 0',
              )
              .join('&&'),
            Select: dynamic.select(
              'id',
              'catalogName',
              `new { case.id, case.catalogName } as case`,
            ),
          });
          return rtkAdapterDynamicToSource(res) as AxiosResponse<CasePageNavigation[]>;
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getCasePageForm: build.query<GetCasePageFormResponse, string>({
      queryFn: async (id) => {
        try {
          const result = await API.api.casePagesGetAllDynamicList({
            Filter: dynamic.makeFilter('id', id, dynamic.equals),
            Select: dynamic.select(
              'id',
              'catalogName',
              'delay',
              'genderID',
              'fromAge',
              'toAge',
              'autoSkip',
              'required',
            ),
            Take: 1,
          });
          return rtkAdapterDynamicItem(result);
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (_, __, arg) => [{ type: RTK_TAGS.CASES_PAGES, id: arg }],
    }),
    postCasePage: build.mutation<ICasePage, Omit<ICasePage, 'id'>>({
      queryFn: async (data) => {
        try {
          const result = await API.api.casePagesCreateCreate(data);
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.CASES_PAGES }],
    }),
    patchCasePage: build.mutation<void, FixServerObject<ICasePage, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.casePagesPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, arg) => [
        { type: RTK_TAGS.CASES_PAGES },
        { type: RTK_TAGS.CASES_PAGES, id: arg.id },
      ],
    }),
  }),
});

export * from './models';
