import { AxiosResponse } from 'axios';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
  moreOrEquals,
} from 'utils/dynamic';

import { SourceModel } from 'configs';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicCount,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IProduct } from '__generated__/api';
import { IGridProductsParams, IGridProductsResponse, IProductItem } from './models';

const dynamic = dynamicNamespace<IProduct>();
const REVALIDATE_KEY = RTK_TAGS.PRODUCTS;

export const apiProducts = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridProducts: build.query<IGridProductsResponse, IGridProductsParams>({
      queryFn: async ({
        search,
        useForPharmAssist,
        pharmaCompanyID,
        productBrandID,
        countryID,
        dosageFormID,
        medicationID,
        sensitivityID,
        dietaryPreferenceID,
        take: Take,
        skip: Skip,
        orderBy,
        Filter,
        isActive,
      }) => {
        try {
          const result = await API.api.productsGetAllDynamicList({
            Select: dynamic.select(
              'id',
              'catalogName',
              'country.countryName as countryName',
              'country.flag as countryFlag',
              'pharmaCompany.companyName as pharmaCompanyName',
              'productBrand.catalogName as productBrandCatalogName',
              'dosageForm.catalogName as dosageFormCatalogName',
              'isActive',
            ),
            Filter: mergeFilters(
              dynamic.makeFilter(['catalogName', 'productBrand.catalogName'], search, contains),
              dynamic.makeFilter(
                'pharmaCompanyID',
                pharmaCompanyID,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter('productBrandID', productBrandID, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('countryID', countryID, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('dosageFormID', dosageFormID, decoratorIsNotNullable(equals)),
              Filter,
              ...(medicationID
                ? [`filterProductMedications.any(k=>k.medicationID=="${medicationID}")`]
                : []),
              ...(sensitivityID
                ? [`filterProductMedications.any(k=>k.medicationID=="${sensitivityID}")`]
                : []),
              ...(dietaryPreferenceID
                ? [
                    `productDietaryPreferences.any(k=>k.dietaryPreferenceID=="${dietaryPreferenceID}")`,
                  ]
                : []),
              dynamic.makeFilter(
                'useForPharmAssist',
                useForPharmAssist ? true : null,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter(
                'useForVitAssist',
                useForPharmAssist ? null : true,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result as AxiosResponse<IGridProductsResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getSourceProductsByCompanyID: build.query({
      queryFn: async ({
        pharmaCompanyID,
        countryID,
      }: {
        pharmaCompanyID: string;
        countryID: string;
      }) => {
        try {
          const response = await API.api.productsGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName as title'),
            Filter: mergeFilters(
              dynamic.makeFilter('isActive', true, equals),
              dynamic.makeFilter('pharmaCompanyID', pharmaCompanyID, equals),
              dynamic.makeFilter('country.id', countryID, equals),
              dynamic.makeFilter(
                'tenantProducts.Where(k => !k.isPending&&isActive==true&&serialNumber.length > 2).count()',
                1,
                moreOrEquals,
              ),
            ).join('&&'),
            OrderBy: dynamic.orderBy('catalogName', 'asc'),
          });
          return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getSourceFlowEditorProducts: build.query({
      queryFn: async ({ useForPharmAssist }: { useForPharmAssist: boolean }) => {
        try {
          const response = await API.api.productsGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName as title'),
            Filter: mergeFilters(
              dynamic.makeFilter('isActive', true, equals),
              dynamic.makeFilter(
                'useForPharmAssist',
                useForPharmAssist ? true : null,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter(
                'useForVitAssist',
                useForPharmAssist ? null : true,
                decoratorIsNotNullable(equals),
              ),
            ).join('&&'),
            OrderBy: dynamic.orderBy('catalogName', 'asc'),
          });
          return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getProduct: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.productsGetAllDynamicList,
            value: id,
            params: {
              Select: dynamic.select(
                'catalogName',
                'country',
                'countryID',
                'createdDate',
                'dosageForm',
                'dosageFormID',
                'drugRegNumber',
                'filterProductMedicalConditions.Select(l => l.medicalConditionID ) as filterProductMedicalConditions',
                'filterProductMedications.Select(l => l.medicationID ) as filterProductMedications',
                'filterProductSensitivities.Select(l => l.sensitivityID ) as filterProductSensitivities',
                'productCases.Select(l => l.caseID) as productCases',
                'productDietaryPreferences.Select(l => l.dietaryPreferenceID) as productDietaryPreferences',
                'fromAge',
                'gender',
                'genderID',
                'id',
                'isActive',
                'isBrand',
                'isGeneric',
                'isOverTheCounter',
                'mainMedication',
                'mainMedicationID',
                'pharmaCompany',
                'pharmaCompanyID',
                'pharmaCompanyOrderDetails',
                'productBrand',
                'productBrandID',
                'productContents',
                'productIngredients',
                'productLeaflets',
                'quantity',
                'signatureApprovalMain',
                'signatureApprovalSecondary',
                'tenantProducts',
                'toAge',
                'updatedDate',
                'useForPharmAssist',
                'useForVitAssist',
              ),
            },
          });
          return result as AxiosResponse<IProductItem>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postProduct: build.mutation<IProduct, IProduct>({
      queryFn: async (data) => {
        try {
          const result = await API.api.productsCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchProduct: build.mutation<void, PatchPartial<IProduct, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.productsPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteProduct: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.productsDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    getProductsCountByCountry: build.query<number, { countryID: string }>({
      queryFn: async ({ countryID }) => {
        try {
          const result = await API.api.productsGetAllDynamicList({
            Select: dynamic.select('id'),
            Filter: mergeFilters(
              dynamic.makeFilter('isActive', true, equals),
              dynamic.makeFilter('countryID', countryID, decoratorIsNotNullable(equals)),
            ).join('&&'),
            Count: true,
          });
          return rtkAdapterDynamicCount<number>(result as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
  }),
});
