import { Theme, useMediaQuery } from '@mui/material';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';
import { Panel, PrivatePageContent } from './components';
import style from './index.module.scss';

export const PrivateLayout = memo(() => {
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));
  return (
    <div className={style.root}>
      {!isMobile && (
        <div className={style.panel}>
          <Panel />
        </div>
      )}
      <div className={style.content}>
        <Outlet />
      </div>
    </div>
  );
});

export { PrivatePageContent };
