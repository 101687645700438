import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiUserCrmProfiles,
  IGridCrmProfileResponse,
  IGridCrmUsers,
} from 'services/user-crm-profiles';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { API, parseErrorData } from 'utils/service';
import { IUserCrmProfile } from '__generated__/api';
import { AppAsyncThunkConfig } from '../index';
import {
  selectCrmUsersData,
  selectCrmUsersFilters,
  selectCrmUsersOrderBy,
  selectCrmUsersPagination,
} from './selectors';

const dynamic = dynamicNamespace<IUserCrmProfile>();

export const actionCrmUsersGetAllDynamic = createAsyncThunk<
  IGridCrmProfileResponse,
  { page?: number; take?: number } | void,
  AppAsyncThunkConfig
  // @ts-ignore
>(`CRM_USERS/getAllDynamic`, async (payload, { getState }) => {
  try {
    const { search, userCrmProfilePermissionID, isActive } = selectCrmUsersFilters(getState());
    const { take: Take, skip: Skip } = selectCrmUsersPagination(getState());
    const { field, order } = selectCrmUsersOrderBy(getState());

    const params = {
      Filter: mergeFilters(
        dynamic.makeFilter(['firstName', 'lastName'], search, decoratorIsNotNullable(contains)),
        dynamic.makeFilter(
          'userCrmProfilePermissionID',
          userCrmProfilePermissionID,
          decoratorIsNotNullable(equals),
        ),
        dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
      ).join('&&'),
      Select: dynamic.select(
        'id',
        'fullName',
        'userPhoto',
        'appIdentityUserID',
        'email',
        'isActive',
        'userCrmProfilePermissionID',
        'userCrmProfilePermission.title as position',
      ),
      OrderBy: dynamic.orderBy(field, order) || 'userCrmProfilePermission.title,firstName,lastName',
      Count: true,
      Take,
      Skip,
    };
    const { data: result } = await API.api.userCrmProfileGetAllDynamicList(params);
    return result;
  } catch (e: any) {
    throw parseErrorData(e);
  }
});

export const actionCrmUsersChangeItem = createAsyncThunk<
  void,
  Partial<IGridCrmUsers>,
  AppAsyncThunkConfig
>(`CRM_USERS/actionCrmUsersChangeItem`, async (data, { dispatch, getState }) => {
  try {
    const initData = selectCrmUsersData(getState());
    const dataOld = initData.find((item) => item.appIdentityUserID === data.appIdentityUserID);

    const appIdentityUserID = data.appIdentityUserID;
    if (!appIdentityUserID) {
      throw new Error('appIdentityUserID is required');
    }
    await dispatch(
      apiUserCrmProfiles.endpoints.patchUserCrmProfile.initiate({
        dataNew: { ...data, appIdentityUserID },
        dataOld,
      } as any),
    ).unwrap();
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
