import { TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { getHandlerKayPress } from 'utils/handlers';
import { composeFunctions } from 'utils/other';
import { TranslateMap } from '../configs';
import { useFieldProps } from './use-field-props';

interface Options {
  field: ControllerRenderProps<any, any>;
  fieldState: ControllerFieldState;
}

type Props = TextFieldProps & {
  onChangeItem: () => void;
};

export const useFilterInputProps = (props: Props) => {
  const getFieldProps = useFieldProps();
  const { onChangeItem, ...rest } = props;
  return useCallback(
    (options: Options, label?: keyof TranslateMap) => {
      const { field } = options;
      return {
        ...rest,
        ...getFieldProps(options, label),
        helperText: undefined,
        onKeyPress: getHandlerKayPress('Enter', onChangeItem),
        onBlur: composeFunctions(field.onBlur, onChangeItem),
        onClear: onChangeItem,
      };
    },
    [rest, getFieldProps, onChangeItem],
  );
};
