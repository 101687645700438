import { useTranslate } from 'hooks/use-translate';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { parseErrorData } from 'utils/service';

export const useNotify = () => {
  const { t } = useTranslate();

  const onError = useCallback(
    (e: { message?: string }) => {
      const err = parseErrorData(e);
      return toast.error(t(err.message));
    },
    [t],
  );
  const onSuccess = useCallback(
    (message: string = t('success')) => {
      return toast.success(message);
    },
    [t],
  );
  return { onError, onSuccess };
};

interface UseEffectNotifyErrorOptions {
  skip: boolean;
}
export const useEffectNotifyError = (
  e: { message?: string } | undefined | null,
  options?: Partial<UseEffectNotifyErrorOptions>,
) => {
  const { onError } = useNotify();
  const { skip = false } = options || {};
  useEffect(() => {
    if (e && !skip) {
      onError(e);
    }
  }, [e, onError, skip]);
};

interface UseEffectNotifyErrorRtkOptions {
  error?: { message?: string } | undefined | null;
  isLoading?: boolean;
  isFetching?: boolean;
}
export const useEffectNotifyErrorRtk = (options: UseEffectNotifyErrorRtkOptions) => {
  const { isLoading, isFetching, error } = options;

  const skip = isLoading || isFetching;
  useEffectNotifyError(error, { skip });
};
