import { selectAccountUser } from 'store/auth';
import { actionAccountGetUser } from 'store/auth/actions';
import { actionCrmUsersChangeItem } from 'store/crm-users/actions';
import { debounce, put, select, takeEvery } from 'typed-redux-saga';
import { workerErrorNotifyThunk } from 'utils/sagas';

function* watcherAccountChange(action: ReturnType<typeof actionCrmUsersChangeItem.fulfilled>) {
  const currentUser = yield* select(selectAccountUser);

  if (!currentUser) {
    return;
  }
  if (action.meta.arg.appIdentityUserID === currentUser.appIdentityUserID) {
    yield* put(actionAccountGetUser({ isOnTheBackground: true }));
  }
}
export const sagasCrmUsers = [
  debounce(5000, actionCrmUsersChangeItem.fulfilled, watcherAccountChange),
  takeEvery(actionCrmUsersChangeItem.rejected, workerErrorNotifyThunk),
];
