import { AxiosResponse } from 'axios';
import { decoratorRequestGetNextRank } from 'modules/dynamic-service';
import * as dynamic from 'utils/dynamic';
import { API, apiRtk, makePatchArgs, rtkAdapterDynamicToSource, RTK_TAGS } from 'utils/service';
import { FixServerObject, Leave, PickServerObject } from 'utils/types';
import { ICaseDecision } from '__generated__/api';
import { CasePageDecisionItemByCasePage, CasePageDecisionReorderItem } from './models';

const requestGetNextOrderNumber = decoratorRequestGetNextRank(
  API.api.caseDecisionsGetAllDynamicList,
  {
    field: 'orderNumber',
  },
);

export const apiCaseDecisions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getDecisionsByCasePageID: build.query<CasePageDecisionItemByCasePage[], string>({
      queryFn: async (casePageID) => {
        try {
          const result = await API.api.caseDecisionsGetAllDynamicList({
            Filter: dynamic.makeFilter('casePageID', casePageID, dynamic.equals),
            Select: dynamic.select('id', 'orderNumber', 'catalogName'),
            OrderBy: dynamic.orderBy('orderNumber', 'asc'),
          });

          return rtkAdapterDynamicToSource(result) as unknown as AxiosResponse<
            CasePageDecisionItemByCasePage[]
          >;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, __, casePageID) => [
        ...(result
          ? result.map(({ id }) => ({ type: RTK_TAGS.CASES_DECISIONS, id: `id__${id}` }))
          : []),
        { type: RTK_TAGS.CASES_DECISIONS, id: `casePageID__${casePageID}` },
      ],
    }),
    postCaseDecision: build.mutation<ICaseDecision, Leave<Omit<ICaseDecision, 'id'>>>({
      queryFn: async (data) => {
        try {
          const dataWithOrderNumber = await requestGetNextOrderNumber({
            Filter: dynamic.makeFilter('casePageID', data.casePageID, dynamic.equals),
          });

          const result = await API.api.caseDecisionsCreateCreate({
            ...data,
            ...dataWithOrderNumber,
          });

          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, { casePageID }) => [
        { type: RTK_TAGS.CASES_DECISIONS, id: `casePageID__${casePageID}` },
      ],
    }),
    reorderCaseDecisions: build.mutation<void, CasePageDecisionReorderItem[]>({
      queryFn: async (reorderedList) => {
        try {
          await Promise.all(
            reorderedList.map((item) => {
              return API.api.caseDecisionsPatchPartialUpdate(
                ...makePatchArgs({ id: item.id, orderNumber: item.orderNumber }),
              );
            }),
          );
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, list) =>
        list.map(({ id }) => {
          return { type: RTK_TAGS.CASES_DECISIONS, id: `id__${id}` };
        }),
    }),
    patchCaseDecision: build.mutation<
      void,
      Leave<FixServerObject<ICaseDecision, 'id' | 'casePageID'>>
    >({
      queryFn: async (data) => {
        try {
          await API.api.caseDecisionsPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, { id }) => [{ type: RTK_TAGS.CASES_DECISIONS, id: `id__${id}` }],
    }),
    deleteCaseDecision: build.mutation<void, PickServerObject<ICaseDecision, 'id' | 'casePageID'>>({
      queryFn: async (data) => {
        try {
          await API.api.caseDecisionsDeleteDelete(data.id);
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (_, __, { id }) => [{ type: RTK_TAGS.CASES_DECISIONS, id: `id__${id}` }],
    }),
  }),
});

export * from './models';
