import React from 'react';

import style from './index.module.scss';

import { Box, Link as MaterialLink, Typography } from '@mui/material';
import clsx from 'clsx';
import { useTranslate } from 'hooks';

const SITE_URL = process.env.REACT_APP_WEBSITE_URL as string;

interface Props {
  className?: string;
}

export const PageFooter: React.FC<Props> = ({ className }) => {
  const { tp } = useTranslate();
  return (
    <div className={clsx(style.root, className)}>
      <Typography variant={'body2'} component={'div'}>
        <div className={style.copy} dangerouslySetInnerHTML={{ __html: tp('copyright') }} />
      </Typography>
      <Box>
        <MaterialLink
          color={'text.primary'}
          variant={'body2'}
          underline={'hover'}
          href={SITE_URL}
          target={'_blank'}
        >
          {tp('common-page-promo')}
        </MaterialLink>
      </Box>
    </div>
  );
};
