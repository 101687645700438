import { AxiosResponse } from 'axios';
import { patchFiles, postFiles } from 'modules/dynamic-service';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
  orderBy,
  select,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ICountry, ILanguage, IRelationshipBulk } from '__generated__/api';
import {
  COUNTRY_IDS,
  ICountryItem,
  IGetCountryItem,
  IGridCountryParams,
  IGridCountryResponse,
} from './models';

const dynamic = dynamicNamespace<ICountry>();
const REVALIDATE_KEY = RTK_TAGS.COUNTRIES;

export const apiCountries = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceCountries: build.query({
      queryFn: async (arg: void) => {
        try {
          const res = await API.api.countriesGetAllDynamicList({
            Select: select('id', 'countryName', 'flag', 'isActive', 'highlight'),
            Filter: dynamic.makeFilter('highlight', true, equals),
            OrderBy: orderBy('countryName', 'asc'),
          });

          return rtkAdapterDynamicToSource(res) as AxiosResponse<
            Array<Pick<ICountry, 'id' | 'countryName' | 'flag' | 'isActive' | 'highlight'>>
          >;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.COUNTRIES }],
    }),
    getSourceHighlightCountries: build.query({
      queryFn: async (arg: void) => {
        try {
          const res = await API.api.countriesGetAllDynamicList({
            Select: select('id', 'countryName', 'flag', 'isActive'),
            OrderBy: orderBy('countryName', 'asc'),
            Filter: mergeFilters(
              dynamic.makeFilter('highlight', true, decoratorIsNotNullable(equals)),
            ).join('&&'),
          });

          return rtkAdapterDynamicToSource(res) as AxiosResponse<
            Array<Pick<ICountry, 'id' | 'countryName' | 'flag' | 'isActive'>>
          >;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.COUNTRIES }],
    }),
    getGridCountries: build.query<IGridCountryResponse, IGridCountryParams>({
      queryFn: async ({ search, highlight, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await API.api.countriesGetAllDynamicList({
            Select: dynamic.select(
              'id',
              'flag',
              'countryName',
              'countryCode',
              'internationalDailingCode',
              'highlight',
              'isActive',
            ),
            Filter: mergeFilters(
              dynamic.makeFilter(
                ['countryName', 'countryCode', 'internationalDailingCode'],
                search,
                contains,
              ),
              dynamic.makeFilter('highlight', highlight, decoratorIsNotNullable(equals)),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getCountry: build.query<IGetCountryItem, string>({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.countriesGetAllDynamicList,
            value: id,
            params: {
              Select: dynamic.select(
                'id',
                'countryName',
                'flag',
                'countryCode',
                'internationalDailingCode',
                'highlight',
                'isActive',
                'countryLanguages.Select(l => new { l.id, l.languageID }) as countryLanguages',
              ),
            },
          });
          return result as unknown as any;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postCountry: build.mutation({
      queryFn: async (input: Omit<ICountryItem, 'countryLanguageIDs'>) => {
        const [postData, transaction] = await postFiles(input, {
          flag: { isImage: true },
        });

        try {
          return API.api.countriesCreateCreate(postData as unknown as any);
        } catch (e) {
          transaction();
          throw e;
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchCountry: build.mutation({
      queryFn: async ({
        dataNew,
        dataOld,
      }: {
        dataNew: PatchPartial<ICountryItem, 'id'>;
        dataOld?: PatchPartial<ICountryItem, 'id'>;
      }) => {
        const [patchData] = await patchFiles(dataNew, dataOld, {
          flag: { isImage: true },
        });
        try {
          await API.api.countriesPatchPartialUpdate(...makePatchArgs(patchData));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    deleteCountry: build.mutation<void, PatchPartial<ICountry, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.countriesDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    getCountryLanguages: build.query<Pick<ILanguage, 'id' | 'title'>[], string>({
      queryFn: async (countryID) => {
        try {
          const result = await API.api.countriesGetAllDynamicList({
            Filter: dynamic.makeFilter('id', countryID, equals),
            Select: dynamic.select(
              'countryLanguages.Select(cl => new { cl.languageID as id, cl.language.title }) as countryLanguages',
            ),
          });

          const { data } = rtkAdapterDynamicItem(result);

          return { data: data.countryLanguages as Pick<ILanguage, 'id' | 'title'>[] };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getCountryLanguageIDs: build.query<string[], string>({
      queryFn: async (countryID) => {
        try {
          const result = await API.api.countriesGetAllDynamicList({
            Filter: dynamic.makeFilter('id', countryID, equals),
            Select: dynamic.select(
              'countryLanguages.Select(cl => cl.languageID) as countryLanguageIDs',
            ),
          });

          const { data } = rtkAdapterDynamicItem(result) as any;

          return { data: data.countryLanguageIDs as string[] };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getCountriesFlags: build.query({
      queryFn: async (arg: void) => {
        try {
          const result = await API.api.countriesGetAllDynamicList({
            Select: dynamic.select('id', 'flag'),
            Filter: mergeFilters(
              dynamic.makeFilter('id', COUNTRY_IDS.UK, equals),
              dynamic.makeFilter('id', COUNTRY_IDS.IL, equals),
            ).join('||'),
            Count: true,
          });
          return rtkAdapterDynamicToSource(result);
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    postOrPatchCountryLanguageBulk: build.mutation<string, IRelationshipBulk>({
      queryFn: async ({ itemIDs, mainID }) => {
        try {
          const result = await API.api.countryLanguagesCreateBulkCreate({ itemIDs, mainID });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
  }),
});
