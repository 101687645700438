import { Box, BoxProps } from '@mui/material';
import React from 'react';
import style from './index.module.scss';

interface Props extends BoxProps {
  className?: string;
  leftColumnSlot: React.ReactNode;
  rightColumnSlot?: React.ReactNode;
  LeftColumnProps?: Partial<BoxProps>;
  RightColumnProps?: Partial<BoxProps>;
}

export const EditorPageLayoutColumn: React.FC<Props> = ({
  className,
  leftColumnSlot,
  rightColumnSlot,
  LeftColumnProps,
  RightColumnProps,
  ...rest
}) => {
  return (
    <Box className={style.root} {...rest}>
      <Box
        {...LeftColumnProps}
        sx={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: { xs: 'auto', lg: '20rem', xl: '26rem' },
          ...LeftColumnProps?.sx,
        }}
      >
        {leftColumnSlot}
      </Box>
      <div className={style.divider} />
      <Box
        {...RightColumnProps}
        sx={{
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: '100%',
          ...RightColumnProps?.sx,
        }}
      >
        {rightColumnSlot && rightColumnSlot}
      </Box>
    </Box>
  );
};
