import { replaceSpace } from 'utils/other';
import * as yup from 'yup';
import { ILabel, ILabelDynamicResponse } from '__generated__/api';

export const schemaLabels = yup.object({
  title: yup.string().required('rule-required'),
  labelKey: yup.string().required('rule-required').transform(replaceSpace),
  languageID: yup.string().required('rule-required'),
  isActive: yup.boolean().required('rule-required'),
});

export interface IGridLabel extends Pick<ILabel, 'id' | 'title' | 'labelKey' | 'isActive'> {
  languageTitle: string;
}

export interface IGridLabelResponse extends Omit<ILabelDynamicResponse, 'items'> {
  items: IGridLabel[];
}

export enum LABEL_SOURCE_ID {
  CRM = 'f90aa950-0e7b-49fa-a51e-a789fda33108',
  BOT = '280a41e3-1bc2-4cac-a644-087acc4c6859',
  PLATFORM = '3587e9b1-6d36-45ec-84e8-8d3cfc736d37',
  PORTAL = 'dae911be-6526-46cf-81d5-cfebe8c6c6be',
  WEBSITE = '4519a916-03a8-4135-ac26-2fb47c43c863',
}
