import { Link as MaterialLink, Theme, Typography, useMediaQuery } from '@mui/material';
import { useTranslate } from 'hooks';
import { memo } from 'react';
import style from './index.module.scss';

const SITE_URL = process.env.REACT_APP_WEBSITE_URL as string || 'https://www.apowiser.com';
interface Props {}

export const Footer = memo<Props>(() => {
  const { tp } = useTranslate();

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <>
      <footer className={style.footer}>
        <Typography variant={'body2'} color={isMobile ? 'primary.contrastText' : 'text.secondary'}>
          {tp('copyright')}
        </Typography>

        <div className={style.links}>
          <MaterialLink
            color={'primary.contrastText'}
            variant={'body2'}
            underline={'hover'}
            href={SITE_URL}
            target={'_blank'}
          >
            {tp('why-pharmassist')}
          </MaterialLink>
        </div>
      </footer>
    </>
  );
});
