import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, Button, ListItemButton, Popover, Typography, useTheme } from '@mui/material';
import clsx from 'clsx';
import { APP_ROUTES } from 'configs';
import { useAppDispatch, useCurrentUser, useTranslate } from 'hooks';
import React, { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { actionAccountLogout } from 'store/auth';
import { composeFunctions } from 'utils/other';
import style from './index.module.scss';

interface Props {
  isOpen?: boolean;
  isHideTenants?: boolean;
}

export const ProfileNavPanel = memo<Props>(({ isOpen }) => {
  const { t } = useTranslate();
  const dispatch = useAppDispatch();
  const { name, userPhoto } = useCurrentUser();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();
  const onEditProfile = useCallback(() => {
    return navigate(APP_ROUTES.PROFILE().config);
  }, [navigate]);
  const onLogout = useCallback(() => {
    dispatch(actionAccountLogout());
  }, [dispatch]);

  const { spacing } = useTheme();

  return (
    <>
      <Button className={clsx(style.root, isOpen && style.rootOpen)} onClick={handleClick}>
        <Avatar src={userPhoto ?? undefined} sx={{ width: spacing(3.6), height: spacing(3.6) }} />
        <Typography variant={'subtitle2'} noWrap className={style.name}>
          {name}
        </Typography>
        {isOpen && <KeyboardArrowDownIcon className={style.arrow} />}
      </Button>
      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ListItemButton onClick={composeFunctions(onEditProfile, handleClose)}>
          {t('edit-profile')}
        </ListItemButton>
        <ListItemButton onClick={composeFunctions(onLogout, handleClose)}>
          {t('logout')}
        </ListItemButton>
      </Popover>
    </>
  );
});
