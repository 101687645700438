import { AxiosResponse } from 'axios';
import { patchFiles, postFiles, rankItem } from 'modules/dynamic-service';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  behaviourMoveRows,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ILanguage, ILanguageDirection } from '__generated__/api';
import { IGridLanguageParams, IGridLanguageResponse, ILanguageInput } from './models';

const dynamic = dynamicNamespace<ILanguage>();

export interface Language
  extends Required<
    Pick<ILanguage, 'id' | 'icon' | 'isoCode' | 'title' | 'culture' | 'rank' | 'isActive'>
  > {
  id: string;
  direction: ILanguageDirection;
  culture: string;
  title: string;
}

const REVALIDATE_KEY = RTK_TAGS.LANGUAGES;

export const apiLanguages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllLanguages: build.query<Language[], void>({
      queryFn: async () => {
        try {
          const response = await API.api.languagesGetAllDynamicList({
            Select: dynamic.select(
              'id',
              'icon',
              'isoCode',
              'title',
              'culture',
              'rank',
              'direction',
              'isActive',
            ),
            OrderBy: dynamic.orderBy('rank', 'asc'),
          });

          return rtkAdapterDynamicToSource(response) as AxiosResponse<Language[]>;
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getActiveLanguages: build.query<Language[], void>({
      queryFn: async () => {
        try {
          const response = await API.api.languagesGetAllDynamicList({
            OrderBy: dynamic.orderBy('rank', 'asc'),
            Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
          });

          return rtkAdapterDynamicToSource(response) as AxiosResponse<Language[]>;
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getDefaultLanguageID: build.query({
      queryFn: async (arg: void) => {
        try {
          return API.api.languagesGetDefaultLanguageList();
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getGridLanguages: build.query<IGridLanguageResponse, IGridLanguageParams>({
      // @ts-ignore
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await API.api.languagesGetAllDynamicList({
            Select: dynamic.select(
              'id',
              'rank',
              'icon',
              'title',
              'culture',
              'currency',
              'direction',
              'isActive',
            ),
            Filter: mergeFilters(
              dynamic.makeFilter(['title'], search, contains),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getLanguage: build.query<ILanguageInput, string>({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.languagesGetAllDynamicList,
            value: id,
          });
          return result as unknown as any;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postLanguage: build.mutation({
      queryFn: async (input: ILanguageInput) => {
        const [postData, transaction] = await postFiles(input, {
          icon: { isImage: true },
        });

        const data = await rankItem(postData, API.api.languagesGetAllDynamicList, 'rank');
        try {
          return API.api.languagesCreateCreate(data as unknown as any);
        } catch (e) {
          transaction();
          throw e;
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchLanguage: build.mutation({
      queryFn: async ({
        dataNew,
        dataOld,
      }: {
        dataNew: PatchPartial<ILanguageInput, 'id'>;
        dataOld?: PatchPartial<ILanguageInput, 'id'>;
      }) => {
        try {
          const [patchData] = await patchFiles(dataNew, dataOld, {
            icon: { isImage: true },
          });
          await API.api.languagesPatchPartialUpdate(...makePatchArgs(patchData));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    deleteLanguage: build.mutation<void, PatchPartial<ILanguage, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.languagesDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    moveLanguages: build.mutation<
      void,
      { newRows: Partial<ILanguage>[]; oldRows: Partial<ILanguage>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await behaviourMoveRows<ILanguage>({
            mainField: 'id',
            moveField: 'rank',
            newRows,
            oldRows,
            requestPatch: (patchData) =>
              API.api.languagesPatchPartialUpdate(...makePatchArgs(patchData)),
          });
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_KEY },
        ...newRows.map((item) => ({ type: REVALIDATE_KEY, id: item.id || '' })),
      ],
    }),
  }),
});
