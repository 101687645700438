import { Box, Paper } from '@mui/material';
import { animated, useSpring } from '@react-spring/web';
import imgSlider1 from 'images/img-slider-1.jpg';
import imgSliderIcon1 from 'images/img-slider-icon-1.png';
import { memo } from 'react';
import { useOutlet } from 'react-router-dom';
import { Footer, Header, SlickSlider, SlickSliderSlide } from './components';
import style from './index.module.scss';

const calc = (x: number, y: number) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const icon = (x: number, y: number) => `translate3d(${x / -50}px,${y / -30}px,0)`;

export const LoginLayout = memo(() => {
  const outlet = useOutlet();

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 },
  }));

  return (
    <Box
      className={style.wrap}
      onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
    >
      <Header />
      <div className={style.left}>
        <Paper className={style.paper}>
          <div>{outlet}</div>
        </Paper>
      </div>
      <Box className={style.right}>
        <div className={style.slider}>
          <SlickSlider>
            <SlickSliderSlide img={imgSlider1}>
              <animated.div style={{ transform: props.xy.to(icon) }}>
                <img src={imgSliderIcon1} alt="" />
              </animated.div>
            </SlickSliderSlide>
            <SlickSliderSlide img={imgSlider1}>
              <animated.div style={{ transform: props.xy.to(icon) }}>
                <img src={imgSliderIcon1} alt="" />
              </animated.div>
            </SlickSliderSlide>
            <SlickSliderSlide img={imgSlider1}>
              <animated.div style={{ transform: props.xy.to(icon) }}>
                <img src={imgSliderIcon1} alt="" />
              </animated.div>
            </SlickSliderSlide>
          </SlickSlider>
        </div>
      </Box>
      <Footer />
    </Box>
  );
});
