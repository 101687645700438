import { AxiosResponse } from 'axios';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IProductBrand } from '__generated__/api';
import { IGridProductBrandsParams, IGridProductBrandsResponse } from './models';

export interface ProductBrand extends Required<Pick<IProductBrand, 'id' | 'catalogName'>> {}

const dynamic = dynamicNamespace<IProductBrand>();
const REVALIDATE_KEY = RTK_TAGS.PRODUCT_BRANDS;

export const apiProductBrands = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceProductBrands: build.query<ProductBrand[], void>({
      queryFn: async () => {
        try {
          const response = await API.api.productBrandsGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName as title', 'isActive'),
          });

          return rtkAdapterDynamicToSource(response) as AxiosResponse<ProductBrand[]>;
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getGridProductBrands: build.query<IGridProductBrandsResponse, IGridProductBrandsParams>({
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await API.api.productBrandsGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName', 'isActive', 'products.count() as products'),
            Filter: mergeFilters(
              dynamic.makeFilter(['catalogName'], search, contains),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result as AxiosResponse<IGridProductBrandsResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getProductBrand: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.productBrandsGetAllDynamicList,
            value: id,
            params: {
              Select: dynamic.select(
                'id',
                'catalogName',
                'products.count() as products',
                'isActive',
              ),
            },
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postProductBrand: build.mutation<IProductBrand, IProductBrand>({
      queryFn: async (data) => {
        try {
          const result = await API.api.productBrandsCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchProductBrand: build.mutation<void, PatchPartial<IProductBrand, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.productBrandsPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteProductBrand: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.productBrandsDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
