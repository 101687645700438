import { AxiosResponse } from 'axios';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import { API, apiRtk, getByIDRequestHelper, makePatchArgs, RTK_TAGS } from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IOrderStatus } from '__generated__/api';
import { IGridOrderStatusParams, IGridOrderStatusResponse, IOrderStatuses } from './models';

const dynamic = dynamicNamespace<IOrderStatus>();
const REVALIDATE_KEY = RTK_TAGS.ORDER_STATUSES;

export const apiOrderStatuses = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridOrderStatuses: build.query<IGridOrderStatusResponse, IGridOrderStatusParams>({
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await API.api.orderStatusesGetAllDynamicList({
            Select: dynamic.select('id', 'orderStatusType', 'isActive', 'title'),
            Filter: mergeFilters(
              dynamic.makeFilter(['title'], search, contains),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result as AxiosResponse<IGridOrderStatusResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getSourceOrderStatuses: build.query<IOrderStatuses[], void>({
      queryFn: async () => {
        try {
          const { data } = await API.api.orderStatusesGetAllDynamicList({
            Select: dynamic.select('id', 'title'),
            OrderBy: dynamic.orderBy('title', 'asc'),
          });
          return { data: data?.items || [] } as { data: IOrderStatuses[] };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getOrderStatus: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.orderStatusesGetAllDynamicList,
            value: id,
          });
          return result as unknown as any;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postOrderStatus: build.mutation<IOrderStatus, IOrderStatus>({
      queryFn: async (data) => {
        try {
          const result = await API.api.orderStatusesCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchOrderStatus: build.mutation<void, PatchPartial<IOrderStatus, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.orderStatusesPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteOrderStatus: build.mutation<void, PatchPartial<IOrderStatus, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.orderStatusesDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
