import { transformToCatalogName } from 'utils/transformers';
import { FixServerObject } from 'utils/types';
import * as yup from 'yup';
import { ICaseDecision } from '__generated__/api';

export const schemaCaseDecision = yup.object({
  catalogName: yup
    .string()
    .required('rule-required')
    .min(3, 'rule-min-length')
    .catalogName('rule-catalog-name')
    .transform(transformToCatalogName)
    .default(''),
  orderNumber: yup.number().required('rule-required').default(0),
});

export interface CasePageDecisionItemByCasePage
  extends FixServerObject<ICaseDecision, 'id' | 'catalogName' | 'orderNumber'> {}

export interface CasePageDecisionReorderItem
  extends FixServerObject<ICaseDecision, 'id' | 'orderNumber' | 'casePageID'> {}
