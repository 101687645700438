import { AxiosResponse } from 'axios';
import { SourceModel } from 'configs';
import { patchFiles, postFiles } from 'modules/dynamic-service';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IUserCrmProfile } from '__generated__/api';
import {
  CrmProfileInput,
  ICrmUsersGridInput,
  IGridCrmUsersResponse,
  IUserCrmProfileInfo,
} from './models';

export * from './models';

const dynamic = dynamicNamespace<IUserCrmProfile>();

const REVALIDATE_KEY = RTK_TAGS.USER_CRM_PROFILE;

type PatchInput = PatchPartial<CrmProfileInput, 'appIdentityUserID'>;

export const apiUserCrmProfiles = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getCrmUsersGrid: build.query<IGridCrmUsersResponse, ICrmUsersGridInput>({
      queryFn: async (input) => {
        try {
          const result = await API.api.userCrmProfileGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter(
                ['firstName', 'lastName'],
                input.search,
                decoratorIsNotNullable(contains),
              ),
              dynamic.makeFilter(
                'userCrmProfilePermissionID',
                input.userCrmProfilePermissionID,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter('isActive', input.isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            Select: dynamic.select(
              'id',
              'fullName',
              'userPhoto',
              'appIdentityUserID',
              'email',
              'isActive',
              'userCrmProfilePermissionID',
              'userCrmProfilePermission.title as position',
            ),
            OrderBy:
              dynamic.orderBy(input.orderBy) || 'userCrmProfilePermission.title,firstName,lastName',
            Count: true,
            Take: input.take,
            Skip: input.skip,
          });
          return result as AxiosResponse<IGridCrmUsersResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getUserCrmProfileInfo: build.query<IUserCrmProfileInfo, string>({
      queryFn: async (appIdentityUserID: string) => {
        try {
          const result = await API.api.userCrmProfileGetAllDynamicList({
            Select: dynamic.select('id', 'fullName', 'userPhoto'),
            Filter: mergeFilters(
              dynamic.makeFilter(
                'appIdentityUserID',
                appIdentityUserID,
                decoratorIsNotNullable(equals),
              ),
            ).join('&&'),
            Take: 1,
            Count: true,
          });
          return rtkAdapterDynamicItem(result) as AxiosResponse<IUserCrmProfileInfo>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),

    getUserCrmProfileSource: build.query<IUserCrmProfile[], void>({
      queryFn: async () => {
        try {
          const response = await API.api.userCrmProfileGetAllDynamicList({
            Select: dynamic.select('id', 'fullName'),
            Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
            OrderBy: 'isActive desc, firstName,lastName asc',
          });

          return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getUserCrmProfile: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.userCrmProfileGetAllDynamicList,
            value: id,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    getUserProfile: build.query({
      queryFn: async (appIdentityUserID: string) => {
        try {
          const result = await API.api.userCrmProfileGetAllDynamicList({
            Take: 1,
            Filter: dynamic.makeFilter('appIdentityUserID', appIdentityUserID, equals),
          });
          return rtkAdapterDynamicItem(result);
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postUserCrmProfile: build.mutation({
      queryFn: async (input: CrmProfileInput) => {
        const [postData, transaction] = await postFiles(input, {
          userPhoto: { isImage: true },
        });

        try {
          return API.api.userCrmProfileCreateCreate(postData as unknown as any);
        } catch (e) {
          transaction();
          throw e;
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchUserCrmProfile: build.mutation({
      queryFn: async ({ dataNew, dataOld }: { dataNew: PatchInput; dataOld?: PatchInput }) => {
        const [patchData] = await patchFiles(dataNew, dataOld, {
          userPhoto: { isImage: true },
        });

        try {
          await API.api.userCrmProfilePatchPartialUpdate(
            ...makePatchArgs(patchData, 'appIdentityUserID'),
          );
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    deleteUserCrmProfile: build.mutation<void, PatchPartial<IUserCrmProfile, 'appIdentityUserID'>>({
      queryFn: async (data) => {
        try {
          await API.api.userCrmProfileDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
