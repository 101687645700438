import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import { ICountry, ICountryDynamicResponse } from '__generated__/api';

export interface ICountryItem extends Omit<ICountry, 'countryLanguages'> {
  countryLanguageIDs: string[];
}

export interface IGetCountryItem extends Omit<ICountry, 'countryLanguages'> {
  countryLanguages: { id: string; languageID: string }[];
}

export const schemaCountries = yup.object({
  flag: yup.mixed(),
  countryName: yup.string().required('rule-required').default(''),
  countryCode: yup.string().required('rule-required').default(''),
  internationalDailingCode: yup.string().required('rule-required').default(''),
  highlight: yup.boolean().required('rule-required').default(false),
  countryLanguageIDs: yup.array().default([]),
  isActive: yup.boolean().required('rule-required').default(true),
});

export interface IGridCountry
  extends Pick<
    ICountry,
    | 'id'
    | 'flag'
    | 'countryName'
    | 'countryCode'
    | 'internationalDailingCode'
    | 'highlight'
    | 'isActive'
  > {}

export interface ICountryData
  extends Pick<
    ICountry,
    | 'id'
    | 'flag'
    | 'countryName'
    | 'countryCode'
    | 'internationalDailingCode'
    | 'highlight'
    | 'isActive'
  > {
  countryLanguageIDs: string;
}

export interface IGridCountryResponse extends Omit<ICountryDynamicResponse, 'items'> {
  items: IGridCountry[];
}

export interface IGridCountryParams {
  search: string;
  highlight: boolean | null;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  isActive: boolean | null;
}

export enum COUNTRY_IDS {
  UK = '45e59064-9b5b-4cf6-a4ae-b71cef69176f',
  IL = '89dc59ba-3dd9-4ebc-9a9c-5745898be4f4',
}
