import { Paper, PaperProps } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import style from './index.module.scss';

interface Props extends PaperProps {
  className?: string;
  children: React.ReactNode;
}

export const EditorPageLayoutFooter: React.FC<Props> = ({ className, children, ...rest }) => {
  return (
    <Paper className={clsx(style.root, className)} {...rest}>
      {children}
    </Paper>
  );
};
