import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGridPageInput } from 'services/cases/models';
import { IAppPagination } from 'utils/app-helpers';
import { DynamicOrder } from 'utils/dynamic';
interface GridFilters
  extends Pick<IGridPageInput, 'search' | 'caseCategoryID' | 'genderID' | 'countryID'> {
  isActive: null | string | boolean;
}

interface InitState {
  gridFilters: GridFilters;
  gridOrder: DynamicOrder;
  gridPagination: IAppPagination;
}

export const defaultCasePagesGridFilters = {
  search: '',
  caseCategoryID: '',
  countryID: '',
  genderID: '',
  isActive: 'true',
};

const initState = (): InitState => {
  return {
    gridFilters: defaultCasePagesGridFilters,
    gridOrder: { field: 'catalogName', order: 'asc' },
    gridPagination: {
      take: 25,
      page: 1,
    },
  };
};

const slice = createSlice({
  name: 'CASE_PAGES',
  initialState: initState(),
  reducers: {
    actionCasePagesGridSetFilters(state, action: PayloadAction<Partial<GridFilters>>) {
      state.gridFilters = { ...state.gridFilters, ...action.payload };
      state.gridPagination.page = 1;
    },
    actionCasePagesGridResetFilters(state) {
      state.gridFilters = initState().gridFilters;
      state.gridPagination.page = 1;
    },
    actionCasePagesGridSetOrder(state, action: PayloadAction<Partial<DynamicOrder>>) {
      state.gridOrder = { ...state.gridOrder, ...action.payload };
      state.gridPagination.page = 1;
    },
    actionCasePagesGridSetPagination(state, action: PayloadAction<Partial<IAppPagination>>) {
      state.gridPagination = { ...state.gridPagination, ...action.payload };
    },
  },
});
export const {
  actionCasePagesGridSetOrder,
  actionCasePagesGridSetFilters,
  actionCasePagesGridResetFilters,
  actionCasePagesGridSetPagination,
} = slice.actions;

export const reducerCasePages = slice.reducer;
