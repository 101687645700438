import { createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import {
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
} from 'reactflow';
import { apiCaseDecisions } from 'services/case-decisions';
import { apiCasePages } from 'services/case-pages';
import { setToModel } from 'utils/other';
import { ICPType } from '__generated__/api';
import {
  actionFlowEditorAddNode,
  actionFlowEditorAutoAlign,
  actionFlowEditorConnectNodes,
  actionFlowEditorInit,
  actionFlowEditorPerformRemoveNodes,
  actionFlowEditorUpdateNode,
  actionFlowEditorUpdateNodeDecision,
  FlowCasePage,
  FlowNodeData,
} from './actions';

const makeEdgeID = (edgeData: FlowEdgeData) => {
  return [
    'edge',
    `casePageID[${edgeData.casePageID}]`,
    `nextCasePageID[${edgeData.nextCasePageID}]`,
    edgeData.caseDecisionID && `caseDecisionID[${edgeData.caseDecisionID}]`,
  ]
    .filter(Boolean)
    .join('__');
};

export type FlowNode = Node<FlowNodeData, ICPType>;

export type FlowEdgeData = {
  casePageID: string;
  nextCasePageID: string;
  caseDecisionID: string | null;
};
export type FlowEdge = Edge<FlowEdgeData>;

const makeFlowEdges = (casePages: FlowCasePage[]): FlowEdge[] => {
  const signeEdges = casePages
    .filter((page) => page.nextCasePageID)
    .map((page) => {
      const casePageID = String(page.id);
      const nextCasePageID = String(page.nextCasePageID);

      const edgeData = { casePageID, nextCasePageID, caseDecisionID: null };

      return {
        id: makeEdgeID(edgeData),
        source: casePageID,
        target: nextCasePageID,
        data: edgeData,
      };
    });

  const decisionEdges = casePages
    .filter((page) => page.caseDecisions.length)
    .map((page) =>
      page.caseDecisions
        .filter((decision) => decision.nextCasePageID)
        .map((decision) => {
          const caseDecisionID = String(decision.id);
          const nextCasePageID = String(decision.nextCasePageID);

          const casePageID = String(page.id);

          const edgeData = { casePageID, nextCasePageID, caseDecisionID };

          return {
            id: makeEdgeID(edgeData),
            source: casePageID,
            sourceHandle: caseDecisionID,
            target: nextCasePageID,
            data: edgeData,
            label: decision.catalogName,
          };
        }),
    )
    .flat();

  return [...signeEdges, ...decisionEdges];
};

interface InitState {
  isInit: boolean;
  isLoading: boolean;
  error: null | SerializedError;

  caseID: string | null;

  nodes: FlowNode[];
  edges: FlowEdge[];

  nodeIDsToRemove: string[];
}

const initState = (): InitState => {
  return {
    isInit: false,
    isLoading: false,

    caseID: null,

    error: null,
    nodes: [],
    edges: [],

    nodeIDsToRemove: [],
  };
};

const slice = createSlice({
  name: 'FLOW_EDITOR',
  initialState: initState(),
  reducers: {
    actionFlowEditorClear(state) {
      return initState();
    },

    actionFlowEditorNodesChanges(state, action: PayloadAction<NodeChange[]>) {},
    actionFlowEditorNodesApplyChanges(state, action: PayloadAction<NodeChange[]>) {
      state.nodes = applyNodeChanges(action.payload, state.nodes) as unknown as FlowNode[];
    },

    actionFlowEditorEdgeChanges(state, action: PayloadAction<EdgeChange[]>) {},
    actionFlowEditorEdgeApplyChanges(state, action: PayloadAction<EdgeChange[]>) {
      state.edges = applyEdgeChanges(action.payload, state.edges) as unknown as FlowEdge[];
    },

    actionFlowEditorConnect(state, action: PayloadAction<Connection>) {},

    actionFlowEditorSetNodeIDsToRemove(state, action: PayloadAction<string[]>) {
      state.nodeIDsToRemove = action.payload;
    },

    actionFlowEditorRemoveNode(state, action: PayloadAction<string>) {
      state.nodeIDsToRemove.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(actionFlowEditorInit.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(actionFlowEditorInit.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isInit = true;

        state.caseID = action.meta.arg;

        state.nodes = action.payload.map((page) => {
          const { x, y } = page;
          const oldNode = state.nodes.find((node) => node.id === String(page.id));
          return {
            ...oldNode,
            id: String(page.id),
            type: page.type as unknown as ICPType,
            position: { x: Number(x), y: Number(y) },
            data: {
              label: page.catalogName ?? undefined,
              casePageData: page,
              isLoading: false,
              isUpdating: false,
            },
          };
        });
        state.edges = makeFlowEdges(action.payload);
      })
      .addCase(actionFlowEditorInit.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
        state.isInit = true;
      });

    builder
      .addCase(actionFlowEditorUpdateNode.pending, (state, action) => {
        const node = state.nodes.find((node) => {
          return node.data.casePageData.id === action.meta.arg.id;
        });

        if (!node) {
          return state;
        }

        node.data.isUpdating = true;
      })
      .addCase(actionFlowEditorUpdateNode.fulfilled, (state, action) => {
        const node = state.nodes.find((node) => {
          return node.data.casePageData.id === action.meta.arg.id;
        });

        if (!node) {
          return state;
        }

        node.data.isUpdating = false;
        node.data.casePageData = {
          ...node.data.casePageData,
          ...action.meta.arg,
          caseDecisions: node.data.casePageData.caseDecisions,
        };
      })
      .addCase(actionFlowEditorUpdateNode.rejected, (state, action) => {
        const node = state.nodes.find((node) => {
          return node.data.casePageData.id === action.meta.arg.id;
        });

        if (!node) {
          return state;
        }

        node.data.isUpdating = false;
      });

    builder
      .addCase(actionFlowEditorAddNode.pending, (state, action) => {
        const { fakeID, x, y, catalogName, cpType } = action.meta.arg;
        state.nodes.push({
          id: fakeID,
          type: cpType as unknown as ICPType,
          position: { x: Number(x), y: Number(y) },
          data: {
            isLoading: true,
            isUpdating: false,
            casePageData: {
              id: null,
              catalogName: catalogName,
              x,
              y,
              nextCasePageID: null,
              type: cpType,
              typeTitle: catalogName,
              caseDecisions: [],
            },
          },

          deletable: false,
          draggable: false,
        });
      })
      .addCase(actionFlowEditorAddNode.fulfilled, (state, action) => {
        const payload = action.payload;
        const node = state.nodes.find((node) => node.id === action.meta.arg.fakeID);

        if (!node) {
          return state;
        }

        node.id = String(payload.id);
        node.data.isLoading = false;
        node.data.casePageData = { ...node.data.casePageData, ...payload, caseDecisions: [] };
        node.draggable = true;
        node.deletable = true;
      })
      .addCase(actionFlowEditorAddNode.rejected, (state, action) => {
        const { fakeID } = action.meta.arg;
        state.nodes = state.nodes.filter((node) => node.id !== fakeID);
      });

    builder.addCase(actionFlowEditorPerformRemoveNodes.pending, (state, action) => {
      state.nodeIDsToRemove = [];

      const nodeIDsToRemove = action.meta.arg;
      state.nodes = state.nodes.filter((node) => {
        const isShouldRemove = nodeIDsToRemove.some((id) => id === node.id);

        return !isShouldRemove;
      });

      state.edges = state.edges.filter((edge) => {
        const shouldRemove = [edge.source, edge.target].some((edgeNodeID) => {
          return nodeIDsToRemove.some((nodeID) => nodeID === edgeNodeID);
        });

        return !shouldRemove;
      });
    });

    builder
      .addCase(actionFlowEditorConnectNodes.pending, (state, action) => {
        const { casePageID, caseDecisionID, nextCasePageID } = action.meta.arg;

        const sourceNode = state.nodes.find((node) => {
          return node.data.casePageData.id === casePageID;
        });

        if (!sourceNode) {
          return state;
        }

        const sourceDecision = sourceNode.data.casePageData.caseDecisions.find((decision) => {
          return decision.id === caseDecisionID;
        });

        state.edges = state.edges.filter((edge) => {
          const isShouldDelete = caseDecisionID
            ? edge.source === casePageID && edge.sourceHandle === caseDecisionID
            : edge.source === casePageID;

          return !isShouldDelete;
        });

        state.edges = [
          ...state.edges,
          {
            id: makeEdgeID(action.meta.arg),
            label: sourceDecision?.catalogName,
            source: casePageID,
            sourceHandle: caseDecisionID,
            target: nextCasePageID,
          },
        ];

        if (sourceDecision) {
          sourceDecision.nextCasePageID = nextCasePageID;
        } else {
          sourceNode.data.casePageData.nextCasePageID = nextCasePageID;
        }
      })
      .addCase(actionFlowEditorConnectNodes.fulfilled, (state, action) => {})
      .addCase(actionFlowEditorConnectNodes.rejected, (state, action) => {});

    builder.addCase(actionFlowEditorUpdateNodeDecision.pending, (state, action) => {
      const { casePageID } = action.meta.arg;
      const node = state.nodes.find((node) => {
        return (node.data.casePageData.id = casePageID);
      });

      if (!node) {
        return state;
      }

      node.data.casePageData.caseDecisions = node.data.casePageData.caseDecisions.map(
        (decision) => {
          if (decision.id === action.meta.arg.id) {
            return { ...decision, ...action.meta.arg };
          }
          return decision;
        },
      );
    });

    builder
      .addCase(actionFlowEditorAutoAlign.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(actionFlowEditorAutoAlign.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(actionFlowEditorAutoAlign.rejected, (state) => {
        state.isLoading = false;
      });

    // rtk query
    builder.addMatcher(apiCasePages.endpoints.patchCasePage.matchFulfilled, (state, action) => {
      const casePage = action.meta.arg.originalArgs;

      const node = state.nodes.find((node) => node.id === casePage.id);

      if (!node) {
        return state;
      }

      node.data.casePageData = setToModel(node.data.casePageData, {
        ...casePage,
      } as unknown as any);
    });

    builder
      .addMatcher(apiCaseDecisions.endpoints.postCaseDecision.matchFulfilled, (state, action) => {
        const { casePageID } = action.meta.arg.originalArgs;
        const caseDecision = action.payload;

        const node = state.nodes.find((node) => node.id === casePageID);

        if (!node) {
          return state;
        }

        node.data.casePageData.caseDecisions.push(caseDecision);
      })
      .addMatcher(apiCaseDecisions.endpoints.patchCaseDecision.matchFulfilled, (state, action) => {
        const { id, casePageID } = action.meta.arg.originalArgs;

        const node = state.nodes.find((node) => node.id === casePageID);

        if (!node) {
          return state;
        }

        node.data.casePageData.caseDecisions = node.data.casePageData.caseDecisions.map(
          (decision) => {
            if (decision.id === id) {
              return { ...decision, ...action.meta.arg.originalArgs };
            }
            return decision;
          },
        );

        const edge = state.edges.find((edge) => {
          return edge.sourceHandle === id;
        });

        if (edge && edge.data) {
          edge.data = setToModel(edge.data, action.meta.arg.originalArgs as any);
          edge.label = action.meta.arg.originalArgs.catalogName || edge.label;
        }
      })
      .addMatcher(apiCaseDecisions.endpoints.deleteCaseDecision.matchFulfilled, (state, action) => {
        const { id, casePageID } = action.meta.arg.originalArgs;

        const node = state.nodes.find((node) => node.id === casePageID);

        if (!node) {
          return state;
        }

        node.data.casePageData.caseDecisions = node.data.casePageData.caseDecisions.filter(
          (decision) => {
            return decision.id !== id;
          },
        );

        state.edges = state.edges.filter((edge) => {
          return edge.sourceHandle !== id;
        });
      });

    builder.addMatcher(
      apiCaseDecisions.endpoints.reorderCaseDecisions.matchFulfilled,
      (state, action) => {
        const list = action.meta.arg.originalArgs;

        const casePageID = list[0]?.casePageID;

        if (!casePageID) {
          return state;
        }

        const node = state.nodes.find((node) => node.id === casePageID);

        if (!node) {
          return state;
        }
        node.data.casePageData.caseDecisions = node.data.casePageData.caseDecisions
          .map((item) => {
            const reorderedItem = list.find((decision) => decision.id === item.id);
            return { ...item, ...reorderedItem };
          })
          .sort((a, b) => Number(a.orderNumber) - Number(b.orderNumber));
      },
    );
  },
});
export const {
  actionFlowEditorEdgeChanges,
  actionFlowEditorEdgeApplyChanges,
  actionFlowEditorNodesChanges,
  actionFlowEditorNodesApplyChanges,
  actionFlowEditorClear,
  actionFlowEditorConnect,
  actionFlowEditorSetNodeIDsToRemove,
  actionFlowEditorRemoveNode,
} = slice.actions;

export const reducerFlowEditor = slice.reducer;
