import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../index';

const selectState = (state: AppState) => state.flowEditor;

export const selectFlowEditorStatuses = createSelector(selectState, (state) => {
  const { isInit, isLoading, error } = state;
  return { isInit, isLoading, error };
});

export const selectFlowEditorCaseID = createSelector(selectState, (state) => {
  return state.caseID;
});

export const selectFlowEditorNodeIDsToRemove = createSelector(
  selectState,
  ({ nodeIDsToRemove }) => {
    return nodeIDsToRemove;
  },
);

export const selectFlowEditorNodes = createSelector(selectState, ({ nodes }) => {
  return nodes;
});

export const selectFlowEditorNodesSelected = createSelector(selectFlowEditorNodes, (nodes) => {
  return nodes.filter((node) => node.selected);
});

export const selectFlowEditorIsSomeUpdating = createSelector(selectFlowEditorNodes, (nodes) => {
  return nodes.some((node) => node.data.isUpdating);
});

export const selectFlowEditorNodesMap = createSelector(selectFlowEditorNodes, (nodes) => {
  return new Map(nodes.map((node) => [node.id, node]));
});

export const selectFlowEditorDecisions = createSelector(selectFlowEditorNodes, (nodes) => {
  return nodes
    .map((node) => node.data.casePageData.caseDecisions.map((decision) => decision).flat())
    .flat();
});
export const selectFlowEditorDecisionsMap = createSelector(
  selectFlowEditorDecisions,
  (decisions) => {
    return new Map(decisions.map((decision) => [decision.id, decision]));
  },
);

export const selectFlowEditorEdges = createSelector(selectState, ({ edges }) => {
  return edges;
});

export const selectFlowEditorEdgesMap = createSelector(selectFlowEditorEdges, (edges) => {
  return new Map(edges.map((edge) => [edge.id, edge]));
});
