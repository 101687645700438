import { AxiosResponse } from 'axios';
import { COUNTRY_IDS } from 'services/countries/models';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  makeFilter,
  mergeFilters,
  orderBy,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IPharmaCompany } from '__generated__/api';
import {
  IGridPharmaCompaniesParams,
  IGridPharmaCompaniesResponse,
  IPharmaCompanies,
  IPharmaCompaniesChartResponse,
} from './models';
export interface PharmaCompany extends Required<Pick<IPharmaCompany, 'id' | 'companyName'>> {}

export * from './models';

const dynamic = dynamicNamespace<IPharmaCompany>();
const REVALIDATE_KEY = RTK_TAGS.PHARMA_COMPANIES;

export const apiPharmaCompanies = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourcePharmaCompanies: build.query<IPharmaCompanies[], void>({
      queryFn: async () => {
        try {
          const { data } = await API.api.pharmaCompaniesGetAllDynamicList({
            Select: dynamic.select('id', 'companyName as title'),
            OrderBy: dynamic.orderBy('companyName', 'asc'),
          });
          return { data: data?.items || [] } as { data: IPharmaCompanies[] };
        } catch (e: any) {
          return { error: e };
        }
      },
    }),
    getAllPharmaCompanies: build.query<PharmaCompany[], void>({
      queryFn: async () => {
        try {
          const response = await API.api.pharmaCompaniesGetAllDynamicList({
            Select: dynamic.select('id', 'companyName as title'),
          });

          return rtkAdapterDynamicToSource(response) as AxiosResponse<PharmaCompany[]>;
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getGridPharmaCompanies: build.query<IGridPharmaCompaniesResponse, IGridPharmaCompaniesParams>({
      queryFn: async ({ search, countryID, take, skip, orderBy: order, isActive }) => {
        try {
          const result = await API.api.pharmaCompaniesGetAllDynamicList({
            Select: dynamic.select(
              'id',
              'country.countryName as countryName',
              'countryID',
              'companyName',
              'pharmaCompanyOrders.count() as orders',
              'products.count() as products',
              'isActive',
            ),
            Filter: mergeFilters(
              dynamic.makeFilter(['companyName', 'country.countryName'], search, contains),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
              makeFilter<IPharmaCompany>('countryID', countryID, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: orderBy(order.field, order.order),
            Take: take,
            Skip: skip,
            Count: true,
          });

          return result as AxiosResponse<IGridPharmaCompaniesResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getPharmaCompany: build.query<IPharmaCompany, { id: string }>({
      queryFn: async ({ id }) => {
        try {
          const { data } = await getByIDRequestHelper({
            apiFunc: API.api.pharmaCompaniesGetAllDynamicList,
            value: id,
            params: {
              Select: dynamic.select(
                'id',
                'companyName',
                'countryID',
                'products.count() as products',
                'isActive',
              ),
            },
          });

          return { data } as { data: IPharmaCompany };
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (_, __, { id }) => [{ type: REVALIDATE_KEY, id }],
    }),
    postPharmaCompany: build.mutation<IPharmaCompany, IPharmaCompany>({
      queryFn: async (data) => {
        try {
          const result = await API.api.pharmaCompaniesCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchPharmaCompany: build.mutation<void, PatchPartial<IPharmaCompany, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.pharmaCompaniesPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deletePharmaCompany: build.mutation<void, PatchPartial<IPharmaCompany, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.pharmaCompaniesDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    getChartPharmaCompanies: build.query<IPharmaCompaniesChartResponse, void>({
      queryFn: async () => {
        try {
          const result = await API.api.pharmaCompaniesGetAllDynamicList({
            Filter: dynamic.makeFilter('isActive', true, equals),
            Select: dynamic.select(
              'id',
              'companyName',
              `products.Where(k=>k.countryID=="${COUNTRY_IDS.IL}").count() as productsCount`,
            ),
            Count: true,
          });
          return result as AxiosResponse<IPharmaCompaniesChartResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
  }),
});
