import { toast } from 'react-toastify';
import { Action } from 'redux';
import { selectAccountLabelsMap } from 'store/auth';
import { select } from 'typed-redux-saga';
import { parseErrorData } from 'utils/service';

interface ThunkAction<E extends { message: string }> extends Action<string> {
  error: E;
}
export function* workerErrorNotifyThunk<T extends ThunkAction<Error>>(data: T) {
  if (data.error) {
    yield* notifyErrorSaga(data.error);
  }
}

interface SagaAction<E extends { message: string }> extends Action<any> {
  payload: { error: E };
}
export function* workerErrorNotifySaga<T extends SagaAction<Error>>(action: T) {
  if (action.payload.error) {
    yield* notifyErrorSaga(action.payload.error);
  }
}

export function* notifyErrorSaga(e: { message: string }) {
  const error = parseErrorData(e);
  const mapLabels = yield* select(selectAccountLabelsMap);

  const labelKey = error.message || 'error';

  const title = mapLabels[labelKey]?.title || labelKey;

  yield toast.error(title);
}
