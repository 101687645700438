import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupIcon from '@mui/icons-material/Group';
import Groups2Icon from '@mui/icons-material/Groups2';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import JavascriptIcon from '@mui/icons-material/Javascript';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MedicationIcon from '@mui/icons-material/Medication';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import TranslateIcon from '@mui/icons-material/Translate';
import { Collapse, Typography, TypographyProps } from '@mui/material';
import clsx from 'clsx';
import { NativeScroll } from 'components/native-scroll';
import { APP_ROUTES } from 'configs';
import { IS_DEV } from 'configs/const';
import { useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { NavigationItem, NavigationOption } from '../navigation-item';
import style from './index.module.scss';

interface CategoryTitleProps extends TypographyProps {}

const CategoryTitle: React.FC<CategoryTitleProps> = (props) => {
  return (
    <Typography
      color={'textSecondary'}
      variant={'body2'}
      fontWeight={600}
      textTransform={'capitalize'}
      pl={'2.4rem'}
      pr={'2.4rem'}
      {...props}
    />
  );
};

interface Props {
  isMenuOpen: boolean;
  onMenuClose?: () => void;
  onMenuOpen?: () => void;
}

export const NavigationList: React.FC<Props> = ({ isMenuOpen, onMenuOpen, onMenuClose }) => {
  const { tp } = useTranslate();

  const optionsOverview = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('dashboard'),
        Icon: <DashboardOutlinedIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.DASHBOARD().config,
      },
    ];
  }, [tp]);

  const optionsManagement = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('products'),
        Icon: <AddShoppingCartIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.PRODUCTS().config,
      },
      {
        title: tp('tenants'),
        Icon: <Groups2Icon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.TENANTS().config,
      },
      {
        title: tp('flow-editor'),
        Icon: <BorderColorIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.CASE_PAGES().config,
      },
      {
        title: tp('orders'),
        Icon: <ShoppingBasketIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.ORDERS().config,
      },
    ];
  }, [tp]);

  const optionsParameters = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('pharmaceutical-parameters'),
        Icon: <MedicationIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          {
            title: tp('genders'),
            to: APP_ROUTES.GENDERS().config,
          },
          {
            title: tp('case-categories'),
            to: APP_ROUTES.CASE_CATEGORIES().config,
          },
          {
            title: tp('sensitivities'),
            to: APP_ROUTES.SENSITIVITIES().config,
          },
          {
            title: tp('active-ingredients'),
            to: APP_ROUTES.ACTIVE_INGREDIENTS().config,
          },
          {
            title: tp('medical-conditions'),
            to: APP_ROUTES.MEDICAL_CONDITIONS().config,
          },
          {
            title: tp('dietary-preferences'),
            to: APP_ROUTES.DIETARY_PREFERENCES().config,
          },
          {
            title: tp('dosage-form-categories'),
            to: APP_ROUTES.DOSAGE_FORM_CATEGORIES().config,
          },
          {
            title: tp('dosage-forms'),
            to: APP_ROUTES.DOSAGE_FORMS().config,
          },
          {
            title: tp('dosage-form-unit-types'),
            to: APP_ROUTES.DOSAGE_FORM_UNIT_TYPES().config,
          },
        ],
      },
      {
        title: tp('general-parameters'),
        Icon: <SettingsIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          {
            title: tp('pharma-companies'),
            to: APP_ROUTES.PHARMA_COMPANIES().config,
          },
          {
            title: tp('brands'),
            to: APP_ROUTES.BRANDS().config,
          },
          {
            title: tp('countries'),
            to: APP_ROUTES.COUNTRIES().config,
          },
          {
            title: tp('order-statuses'),
            to: APP_ROUTES.ORDER_STATUSES().config,
          },
          {
            title: tp('redis-params'),
            to: APP_ROUTES.SETTINGS_REDIS_PARAMS().config,
          },
        ],
      },
    ];
  }, [tp]);

  const optionsUsers = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('pro-users'),
        Icon: <GroupIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.SETTINGS_PRO_USERS().config,
      },
      {
        title: tp('permissions'),
        Icon: <ManageAccountsIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.SETTINGS_PERMISSIONS().config,
      },
    ];
  }, [tp]);

  const optionsConfiguration = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('contents'),
        Icon: <TranslateIcon color={'inherit'} fontSize={'inherit'} />,
        options: [
          {
            title: tp('languages'),
            to: APP_ROUTES.SETTINGS_LANGUAGES().config,
          },
          {
            title: tp('labels'),
            to: APP_ROUTES.SETTINGS_LABELS().config,
          },
        ],
      },
    ];
  }, [tp]);

  const optionsLogs = useMemo<NavigationOption[]>(() => {
    return [
      {
        title: tp('pro-logs'),
        Icon: <IntegrationInstructionsIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.PRO_LOGS().config,
      },
      {
        title: tp('customer-portal-logs'),
        Icon: <IntegrationInstructionsIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.CUSTOMER_PORTAL_LOGS().config,
      },
      {
        title: tp('cms-logs'),
        Icon: <IntegrationInstructionsIcon color={'inherit'} fontSize={'inherit'} />,
        to: APP_ROUTES.CMS_LOGS().config,
      },
    ].concat(
      IS_DEV
        ? [
            {
              title: '__DEVELOPMENT__',
              Icon: <JavascriptIcon color={'inherit'} fontSize={'inherit'} />,
              to: APP_ROUTES.__DEVELOPMENT_TEST().config,
            },
          ]
        : [],
    );
  }, [tp]);

  return (
    <NativeScroll mode={'always'} size={'small'} sx={{ overflowX: 'hidden' }}>
      <div className={clsx(style.menuHelper, isMenuOpen && style.hide)} />
      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'1.4rem'} pb={'1rem'}>
          {tp('overview')}
        </CategoryTitle>
      </Collapse>
      {optionsOverview.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            level={1}
          />
        );
      })}

      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'2rem'} pb={'1rem'}>
          {tp('management')}
        </CategoryTitle>
      </Collapse>
      {optionsManagement.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            level={1}
          />
        );
      })}

      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'2rem'} pb={'1rem'}>
          {tp('parameters')}
        </CategoryTitle>
      </Collapse>
      {optionsParameters.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            level={1}
          />
        );
      })}

      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'2rem'} pb={'1rem'}>
          {tp('users')}
        </CategoryTitle>
      </Collapse>
      {optionsUsers.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            level={1}
          />
        );
      })}

      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'2rem'} pb={'1rem'}>
          {tp('configuration')}
        </CategoryTitle>
      </Collapse>
      {optionsConfiguration.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            level={1}
          />
        );
      })}

      <Collapse in={isMenuOpen}>
        <CategoryTitle pt={'2rem'} pb={'1rem'}>
          {tp('logs')}
        </CategoryTitle>
      </Collapse>
      {optionsLogs.map((option, i) => {
        return (
          <NavigationItem
            key={i}
            Icon={option.Icon}
            options={option.options}
            title={option.title}
            isMenuOpen={isMenuOpen}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            to={option.to}
            level={1}
          />
        );
      })}
    </NativeScroll>
  );
};
