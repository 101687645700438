import { AxiosResponse } from 'axios';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
  select,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IMedicalCondition } from '__generated__/api';
import {
  IGridMedicalConditionsParams,
  IGridMedicalConditionsResponse,
  ISourceMedicalConditionsParams,
} from './models';

const dynamic = dynamicNamespace<IMedicalCondition>();
const REVALIDATE_KEY = RTK_TAGS.MEDICAL_CONDITIONS;

export const apiMedicalConditions = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceMedicalConditions: build.query({
      queryFn: async ({
        useForPharmAssist,
        useForVitAssist,
      }: Partial<ISourceMedicalConditionsParams>) => {
        try {
          const res = await API.api.medicalConditionsGetAllDynamicList({
            Select: select('id', 'catalogName as title'),
            Filter: mergeFilters(
              dynamic.makeFilter(
                'useForPharmAssist',
                useForPharmAssist,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter(
                'useForVitAssist',
                useForVitAssist,
                decoratorIsNotNullable(equals),
              ),
            ).join('&&'),
          });

          return rtkAdapterDynamicToSource(res) as AxiosResponse<
            Array<Pick<IMedicalCondition, 'id' | 'catalogName'>>
          >;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.SENSITIVITIES }],
    }),
    getGridMedicalConditions: build.query<
      IGridMedicalConditionsResponse,
      IGridMedicalConditionsParams
    >({
      queryFn: async ({ search, useForPharmAssist, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const response = await API.api.medicalConditionsGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName', 'isActive'),
            Filter: mergeFilters(
              dynamic.makeFilter(['catalogName'], search, contains),
              dynamic.makeFilter(
                'useForPharmAssist',
                useForPharmAssist ? true : null,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter(
                'useForVitAssist',
                useForPharmAssist ? null : true,
                decoratorIsNotNullable(equals),
              ),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });

          return response;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getMedicalCondition: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.medicalConditionsGetAllDynamicList,
            value: id,
          });

          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postMedicalCondition: build.mutation<IMedicalCondition, IMedicalCondition>({
      queryFn: async (data) => {
        try {
          const result = await API.api.medicalConditionsCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchMedicalCondition: build.mutation<void, PatchPartial<IMedicalCondition, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.medicalConditionsPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteMedicalCondition: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.medicalConditionsDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
