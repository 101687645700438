import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import { ICountry, IPharmaCompany, IPharmaCompanyDynamicResponse } from '__generated__/api';

export interface IPharmaCompanies extends Pick<IPharmaCompany, 'id'> {
  title: string;
}

export const schemaPharmaCompanies = yup.object({
  companyName: yup.string().required('rule-required').default(''),
  countryID: yup.string().required('rule-required').default(''),
  isActive: yup.boolean().required('rule-required').default(true),
});

export interface IGridPharmaCompany
  extends Pick<IPharmaCompany, 'id' | 'companyName' | 'products' | 'isActive'> {
  countryName?: ICountry['countryName'];
  orders?: number;
}

export interface PharmaCompanyInput
  extends Pick<IPharmaCompany, 'companyName' | 'isActive' | 'countryID'> {}

export interface IGridPharmaCompaniesResponse extends Omit<IPharmaCompanyDynamicResponse, 'items'> {
  items: IGridPharmaCompany[];
}

export interface IGridPharmaCompaniesParams {
  search: string;
  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
  countryID: string;
  isActive: boolean | null;
}

export interface IPharmaCompaniesChart extends Pick<IPharmaCompany, 'id' | 'companyName'> {
  productsCount: number[];
}

export interface IPharmaCompaniesChartResponse
  extends Omit<IPharmaCompanyDynamicResponse, 'items'> {
  items: IPharmaCompaniesChart[];
}

export const PHARMA_COMPANY_QUERY_NAME = 'pharma-company';
