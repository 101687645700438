import { AxiosResponse } from 'axios';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
  select,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IDosageFormCategory } from '__generated__/api';
import { IGridDosageFormCategoriesParams, IGridDosageFromCategoriesResponse } from './models';

const dynamic = dynamicNamespace<IDosageFormCategory>();
const REVALIDATE_KEY = RTK_TAGS.DOSAGE_FORM_CATEGORIES;

export const apiDosageFormCategories = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridDosageFormCategories: build.query<
      IGridDosageFromCategoriesResponse,
      IGridDosageFormCategoriesParams
    >({
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await API.api.dosageFormCategoriesGetAllDynamicList({
            Select: dynamic.select('id', 'catalogName', 'isActive'),
            Filter: mergeFilters(
              dynamic.makeFilter(['catalogName'], search, contains),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getSourceDosageFormCategories: build.query({
      queryFn: async (arg: void) => {
        try {
          const res = await API.api.dosageFormCategoriesGetAllDynamicList({
            Select: select(
              'id',
              'catalogName as title',
              'dosageFormCategoryContents.Count as countContent',
            ),
            Filter: dynamic.makeFilter('isActive', true, equals),
          });

          return rtkAdapterDynamicToSource(res) as AxiosResponse<
            Array<Pick<IDosageFormCategory, 'id'> & { title: string }>
          >;
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getDosageFormCategories: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.dosageFormCategoriesGetAllDynamicList,
            value: id,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postDosageFormCategories: build.mutation<IDosageFormCategory, IDosageFormCategory>({
      queryFn: async (data) => {
        try {
          const result = await API.api.dosageFormCategoriesCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchDosageFormCategories: build.mutation<void, PatchPartial<IDosageFormCategory, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.dosageFormCategoriesPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteDosageFormCategories: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.dosageFormCategoriesDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
  }),
});
