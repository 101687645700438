import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  HOME: createTypedRoute('/'),
  DASHBOARD: createTypedRoute('/dashboard'),
  PROFILE: createTypedRoute('/profile'),
  PRODUCTS: createTypedRoute('/products'),
  TENANTS: createTypedRoute('/tenants'),
  CASE_PAGES: createTypedRoute('/flow-editor'),
  FLOW_EDITOR_ITEM: createTypedRoute<{ id: string }>('/flow-editor/:id'),
  PHARMA_COMPANIES: createTypedRoute('/pharma-companies'),
  BRANDS: createTypedRoute('/product-brands'),
  COUNTRIES: createTypedRoute('/countries'),
  GENDERS: createTypedRoute('/genders'),
  ORDER_STATUSES: createTypedRoute('/order-statuses'),
  CASE_CATEGORIES: createTypedRoute('/case-categories'),
  SENSITIVITIES: createTypedRoute('/sensitivities'),
  ACTIVE_INGREDIENTS: createTypedRoute('/active-ingredients'),
  MEDICAL_CONDITIONS: createTypedRoute('/medical-conditions'),
  DIETARY_PREFERENCES: createTypedRoute('/dietary-preferences'),
  DOSAGE_FORM_CATEGORIES: createTypedRoute('/dosage-form-categories'),
  DOSAGE_FORMS: createTypedRoute('/dosage-forms'),
  DOSAGE_FORM_UNIT_TYPES: createTypedRoute('/dosage-form-unit-types'),
  ORDERS: createTypedRoute('/pharma-company-orders'),

  SETTINGS: createTypedRoute('/settings'),
  SETTINGS_LANGUAGES: createTypedRoute('/settings/languages'),
  SETTINGS_LABELS: createTypedRoute('/settings/labels'),
  SETTINGS_PRO_USERS: createTypedRoute('/settings/pro-users'),
  SETTINGS_PRO_USER_ITEM: createTypedRoute<{ id: string }>('/settings/pro-users/:id'),
  SETTINGS_PERMISSIONS: createTypedRoute('/settings/permissions'),
  SETTINGS_REDIS_PARAMS: createTypedRoute('/settings/redis-params'),
  SETTINGS_LABELS_FOR_BOT: createTypedRoute('/settings/labels/bot'),
  SETTINGS_LABELS_FOR_PLATFORM: createTypedRoute('/settings/labels/platform'),
  SETTINGS_LABELS_FOR_CRM: createTypedRoute('/settings/labels/crm'),
  SETTINGS_LABELS_FOR_PORTAL: createTypedRoute('/settings/labels/portal'),
  SETTINGS_LABELS_FOR_WEBSITE: createTypedRoute('/settings/labels/website'),

  PRO_LOGS: createTypedRoute('/pro-logs'),
  CUSTOMER_PORTAL_LOGS: createTypedRoute('/customer-portal-logs'),
  CMS_LOGS: createTypedRoute('/cms-logs'),

  LOGIN: createTypedRoute<{ redirect?: string } | void>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),

  __DEVELOPMENT_TEST: createTypedRoute('__dev-test'),
};
