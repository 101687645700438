import { EdgeChange, NodePositionChange, NodeRemoveChange } from 'reactflow';
import { all, call, fork, put, select, takeEvery, takeLatest } from 'typed-redux-saga';
import { workerErrorNotifyThunk } from 'utils/sagas';
import {
  actionFlowEditorAddNode,
  actionFlowEditorAutoAlign,
  actionFlowEditorConnectNodes,
  actionFlowEditorInit,
  actionFlowEditorPerformRemoveNodes,
  actionFlowEditorUpdateNode,
  actionFlowEditorUpdateNodeDecision,
} from './actions';
import {
  selectFlowEditorCaseID,
  selectFlowEditorEdgesMap,
  selectFlowEditorNodesMap,
} from './selectors';
import {
  actionFlowEditorConnect,
  actionFlowEditorEdgeApplyChanges,
  actionFlowEditorEdgeChanges,
  actionFlowEditorNodesApplyChanges,
  actionFlowEditorNodesChanges,
  actionFlowEditorSetNodeIDsToRemove,
} from './slice';

function* sagaWatchConnect(action: ReturnType<typeof actionFlowEditorConnect>) {
  const { target, source, sourceHandle } = action.payload;

  const casePageID = source;
  const nextCasePageID = target;
  const caseDecisionID = sourceHandle;

  if (!casePageID || !nextCasePageID) {
    return;
  }

  yield* put(actionFlowEditorConnectNodes({ casePageID, nextCasePageID, caseDecisionID }));
}
function* sagaControllerNodePositionChange(nodeChange: NodePositionChange) {
  if (nodeChange.dragging) return;

  const mapNodes = yield* select(selectFlowEditorNodesMap);
  const targetNode = mapNodes.get(nodeChange.id);

  if (!targetNode) {
    return;
  }

  const id = targetNode.data.casePageData.id;

  // prevent update new item
  if (!id) {
    return;
  }
  const { x, y } = targetNode.data.casePageData;

  // prevent update if coordinates was not changed
  if (targetNode.position.x === x && targetNode.position.y === y) {
    return;
  }

  yield* put(
    actionFlowEditorUpdateNode({
      id: id,
      x: targetNode.position.x,
      y: targetNode.position.y,
    }),
  );
}
function* sagaControllerNodePositionChanges(nodeChanges: NodePositionChange[]) {
  yield* all(
    nodeChanges.map((nodeChange) => {
      return call(sagaControllerNodePositionChange, nodeChange);
    }),
  );
}

function* sagaWatchNodeChanges(action: ReturnType<typeof actionFlowEditorNodesChanges>) {
  let allowedChanges = action.payload.filter((nodeChange) => {
    return (
      nodeChange.type === 'select' ||
      nodeChange.type === 'dimensions' ||
      nodeChange.type === 'position'
    );
  });

  const removeChanges = action.payload.filter((nodeChange) => {
    return nodeChange.type === 'remove';
  }) as NodeRemoveChange[];

  const positionChanges = allowedChanges.filter((nodeChange) => {
    return nodeChange.type === 'position';
  }) as NodePositionChange[];

  // perform update server
  if (positionChanges.length) {
    yield* fork(sagaControllerNodePositionChanges, positionChanges);
  }

  // handle remove changes by different way
  if (removeChanges.length) {
    yield* put(actionFlowEditorSetNodeIDsToRemove(removeChanges.map(({ id }) => id)));
  }

  if (allowedChanges.length) {
    yield* put(actionFlowEditorNodesApplyChanges(allowedChanges));
  }
}
function* sagaControllerEdgeRemote(edgeChange: EdgeChange) {
  // remove
  if (edgeChange.type === 'remove') {
    const mapEdges = yield* select(selectFlowEditorEdgesMap);

    const edge = mapEdges.get(edgeChange.id);

    if (!edge || !edge.data) {
      return;
    }

    yield* put(actionFlowEditorUpdateNode({ id: edge.data.casePageID, nextCasePageID: null }));

    if (!edge.data.caseDecisionID) {
      return;
    }

    yield* put(
      actionFlowEditorUpdateNodeDecision({
        id: edge.data.caseDecisionID,
        casePageID: edge.data.casePageID,
        nextCasePageID: null,
      }),
    );
  }
}
function* sagaWatchEdgeChanges(action: ReturnType<typeof actionFlowEditorEdgeChanges>) {
  const changes = action.payload;

  yield* all(changes.map((edgeChange) => call(sagaControllerEdgeRemote, edgeChange)));

  yield* put(actionFlowEditorEdgeApplyChanges(changes));
}

function* sagaRefresh() {
  const caseID = yield* select(selectFlowEditorCaseID);

  if (caseID) {
    yield* put(actionFlowEditorInit(caseID));
  }
}

export const sagasFlowEditor = [
  takeEvery(actionFlowEditorConnect, sagaWatchConnect),
  takeEvery(actionFlowEditorNodesChanges, sagaWatchNodeChanges),
  takeEvery(actionFlowEditorEdgeChanges, sagaWatchEdgeChanges),
  takeEvery(
    [
      actionFlowEditorConnectNodes.rejected,
      actionFlowEditorAddNode.rejected,
      actionFlowEditorUpdateNode.rejected,
      actionFlowEditorUpdateNodeDecision.rejected,
      actionFlowEditorPerformRemoveNodes.rejected,
      actionFlowEditorAutoAlign.rejected,
    ],
    workerErrorNotifyThunk,
  ),
  takeLatest(actionFlowEditorPerformRemoveNodes.rejected, sagaRefresh),
];
