import { SourceModel } from 'configs';
import { dynamicNamespace, equals, mergeFilters } from 'utils/dynamic';
import { API, apiRtk, rtkAdapterDynamicToSource } from 'utils/service';
import { IAppLogType } from '__generated__/api';

const dynamic = dynamicNamespace<IAppLogType>();

export const apiAppLogTypes = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllAppLogTypes: build.query<IAppLogType[], void>({
      queryFn: async () => {
        try {
          const response = await API.api.appLogTypesGetAllDynamicList({
            Select: dynamic.select('id', 'title'),
            Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
            OrderBy: dynamic.orderBy('title', 'asc'),
          });

          return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});
