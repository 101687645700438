import { AxiosResponse } from 'axios';
import { SourceModel } from 'configs/types';
import { patchFiles, postFiles } from 'modules/dynamic-service';
import {
  contains,
  dateRange,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  makePatchArgs,
  rtkAdapterDynamicCount,
  rtkAdapterDynamicItem,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { ITenant } from '__generated__/api';
import {
  IGridTenantResponse,
  ITenantGridPageInput,
  ITenantInfoResponse,
  ITenantInput,
  ITenantSessionsArgs,
  ITenantSessionsResponse,
} from './models';

const dynamic = dynamicNamespace<ITenant>();

export const apiTenants = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getGridTenants: build.query<IGridTenantResponse, ITenantGridPageInput>({
      queryFn: async ({ search, skip, take, orderBy, isActive }) => {
        try {
          const result = await API.api.tenantsGetAllDynamicList({
            Filter: mergeFilters(
              dynamic.makeFilter(['companyName'], search, contains),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            Select: dynamic.select(
              'id',
              'companyName',
              'logo',
              'country.countryName as countryName',
              'country.flag as countryFlag',
              'countryID',
              'isActive',
            ),
            Take: take,
            Skip: skip,
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Count: true,
          });

          return result as AxiosResponse<IGridTenantResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: RTK_TAGS.TENANTS }],
    }),
    getTenant: build.query<ITenantInfoResponse, string>({
      queryFn: async (id: string) => {
        try {
          const result = await API.api.tenantsGetAllDynamicList({
            Filter: mergeFilters(dynamic.makeFilter('id', id, equals)).join('&&'),
            Select: dynamic.select(
              'id',
              'companyName',
              'logo',
              'country.countryName as countryName',
              'countryID',
              'tenantProducts.count() as tenantProductsCount',
              'tenantIgnoreProducts.count() as tenantIgnoreProductsCount',
              'userTenants.count() as userTenantsCount',
              'botSessions.count() as botSessionsCount',
              `botSessions.Where(k=>k.botSessionStep.isSessionComplete==true).count() as botCompletedSessionsCount`,
              'isActive',
            ),
            Take: 1,
          });

          return rtkAdapterDynamicItem<ITenantInfoResponse>(result as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: RTK_TAGS.TENANTS, id }],
    }),
    getTenantSessions: build.query<ITenantSessionsResponse, ITenantSessionsArgs>({
      queryFn: async ({ id, entryDate }) => {
        try {
          const result = await API.api.tenantsGetAllDynamicList({
            Filter: mergeFilters(dynamic.makeFilter('id', id, equals)).join('&&'),
            Select: dynamic.select(
              'id',
              `botSessions.Where(l=> ${dynamic.makeFilter(
                'l.createdDate',
                entryDate,
                dateRange,
              )}).Select(k=> new { k.botSessionStep.isSessionComplete, k.createdDate }) as botSessions`,
            ),
            Take: 1,
          });
          return rtkAdapterDynamicItem<ITenantSessionsResponse>(result as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, { id }) => {
        return [{ type: RTK_TAGS.TENANTS, id }];
      },
    }),
    postTenant: build.mutation({
      queryFn: async (input: Omit<ITenantInput, 'id'>) => {
        const [postData, transaction] = await postFiles(input, {
          logo: { isImage: true },
        });

        try {
          return API.api.tenantsCreateCreate(postData as unknown as any);
        } catch (e) {
          transaction();
          throw e;
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.TENANTS }],
    }),
    patchTenant: build.mutation({
      queryFn: async ({
        dataNew,
        dataOld,
      }: {
        dataNew: PatchPartial<ITenantInput, 'id'>;
        dataOld?: PatchPartial<ITenantInput, 'id' | 'logo'>;
      }) => {
        const [patchData] = await patchFiles(dataNew, dataOld, {
          logo: { isImage: true },
        });

        try {
          return API.api.tenantsPatchPartialUpdate(...makePatchArgs(patchData));
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: RTK_TAGS.TENANTS }],
    }),
    deleteTenant: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.tenantsDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: RTK_TAGS.TENANTS },
        { type: RTK_TAGS.TENANTS, id: String(data?.id) },
      ],
    }),
    getSourceTenants: build.query({
      queryFn: async (arg: void) => {
        try {
          const response = await API.api.tenantsGetAllDynamicList({
            Select: dynamic.select('id', 'companyName as title', 'isActive'),
            Filter: mergeFilters(dynamic.makeFilter('isActive', true, equals)).join('&&'),
            OrderBy: dynamic.orderBy('companyName', 'asc'),
          });
          return rtkAdapterDynamicToSource<SourceModel>(response as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getTenantsCount: build.query<number, { countryID: string }>({
      queryFn: async ({ countryID }) => {
        try {
          const response = await API.api.tenantsGetAllDynamicList({
            Select: dynamic.select('id'),
            Filter: mergeFilters(
              dynamic.makeFilter('countryID', countryID, equals),
              dynamic.makeFilter('isActive', true, equals),
            ).join('&&'),
            Count: true,
          });
          return rtkAdapterDynamicCount<number>(response as unknown as any);
        } catch (error: any) {
          return { error };
        }
      },
    }),
  }),
});
