import { AxiosResponse } from 'axios';
import { COUNTRY_IDS } from 'services/countries/models';
import {
  contains,
  decoratorIsNotNullable,
  dynamicNamespace,
  equals,
  mergeFilters,
  select,
} from 'utils/dynamic';
import {
  API,
  apiRtk,
  getByIDRequestHelper,
  makePatchArgs,
  rtkAdapterDynamicToSource,
  RTK_TAGS,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { IDosageForm } from '__generated__/api';
import {
  IDosageFormsChartResponse,
  IGridDosageFormsParams,
  IGridDosageFormsResponse,
} from './models';

const dynamic = dynamicNamespace<IDosageForm>();
const REVALIDATE_KEY = RTK_TAGS.DOSAGE_FORMS;

export const apiDosageForms = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getSourceDosageForms: build.query({
      queryFn: async (arg: void) => {
        try {
          const res = await API.api.dosageFormsGetAllDynamicList({
            Select: select('id', 'catalogName as title', 'isActive'),
          });

          return rtkAdapterDynamicToSource(res) as AxiosResponse<
            Array<Pick<IDosageForm, 'id' | 'isActive'> & { title: string }>
          >;
        } catch (error: any) {
          return { error };
        }
      },
    }),
    getGridDosageForms: build.query<IGridDosageFormsResponse, IGridDosageFormsParams>({
      queryFn: async ({ search, take: Take, skip: Skip, orderBy, isActive }) => {
        try {
          const result = await API.api.dosageFormsGetAllDynamicList({
            Select: dynamic.select(
              'id',
              'catalogName',
              'isActive',
              'dosageFormCategory.catalogName as dosageFormCategoryName',
            ),
            Filter: mergeFilters(
              dynamic.makeFilter(
                ['catalogName', 'dosageFormCategory.catalogName'],
                search,
                contains,
              ),
              dynamic.makeFilter('isActive', isActive, decoratorIsNotNullable(equals)),
            ).join('&&'),
            OrderBy: dynamic.orderBy(orderBy.field, orderBy.order),
            Take,
            Skip,
            Count: true,
          });
          return result as AxiosResponse<IGridDosageFormsResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getDosageForms: build.query({
      queryFn: async (id: string) => {
        try {
          const result = await getByIDRequestHelper({
            apiFunc: API.api.dosageFormsGetAllDynamicList,
            value: id,
          });
          return result;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postDosageForms: build.mutation<IDosageForm, IDosageForm>({
      queryFn: async (data) => {
        try {
          const result = await API.api.dosageFormsCreateCreate(data);
          return { data: result.data };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchDosageForms: build.mutation<void, PatchPartial<IDosageForm, 'id'>>({
      queryFn: async (data) => {
        try {
          await API.api.dosageFormsPatchPartialUpdate(...makePatchArgs(data));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    deleteDosageForms: build.mutation<void, { id: string }>({
      queryFn: async (data) => {
        try {
          await API.api.dosageFormsDeleteDelete(String(data?.id));
          return { data: undefined };
        } catch (error: any) {
          return { error };
        }
      },
      invalidatesTags: (result, error, data) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id: String(data?.id) },
      ],
    }),
    getChartDosageForms: build.query<IDosageFormsChartResponse, void>({
      queryFn: async () => {
        try {
          const result = await API.api.dosageFormsGetAllDynamicList({
            Filter: dynamic.makeFilter('isActive', true, equals),
            Select: dynamic.select(
              'id',
              'catalogName',
              `products.Where(k=>k.countryID=="${COUNTRY_IDS.UK}").count() as productsCountUK`,
              `products.Where(k=>k.countryID=="${COUNTRY_IDS.IL}"&&k.useForPharmAssist).count() as productsCountIL`,
            ),
          });
          return result as AxiosResponse<IDosageFormsChartResponse>;
        } catch (error: any) {
          return { error };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
  }),
});
