import { AppLoading } from 'components/app-loading';

import { APP_ROUTES } from 'configs';
import { EmptyLayout, LoginLayout, PrivateLayout } from 'layouts';
import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';
import { IS_DEV_BUILD } from '../configs/const';
import { LabelsLayout } from '../layouts/labels';

// dashboard
const PageDashboard = React.lazy(
  () => import(/* webpackChunkName: "dashboard" */ 'pages/dashboard'),
);

// profile
const PageProfile = React.lazy(() => import(/* webpackChunkName: "profile" */ 'pages/profile'));

// products
const PageProducts = React.lazy(() => import(/* webpackChunkName: "products" */ 'pages/products'));

// tenants
const PageTenants = React.lazy(() => import(/* webpackChunkName: "tenants" */ 'pages/tenants'));

// flow-editor
const PageFlowEditor = React.lazy(
  () => import(/* webpackChunkName: "flow-editor" */ 'pages/flow-editor'),
);
// flow-editor
const PageFlowEditorItem = React.lazy(
  () => import(/* webpackChunkName: "flow-editor" */ 'pages/flow-editor-item'),
);

// pharma-companies
const PagePharmaCompanies = React.lazy(
  () => import(/* webpackChunkName: "pharma-companies" */ 'pages/pharma-companies'),
);

// brands
const PageBrands = React.lazy(() => import(/* webpackChunkName: "brands" */ 'pages/brands'));

// countries
const PageCountries = React.lazy(
  () => import(/* webpackChunkName: "countries" */ 'pages/countries'),
);

// genders
const PageGenders = React.lazy(() => import(/* webpackChunkName: "genders" */ 'pages/genders'));

// order-statuses
const PageOrderStatuses = React.lazy(
  () => import(/* webpackChunkName: "order-statuses" */ 'pages/order-statuses'),
);

// order-statuses
const PageCaseCategories = React.lazy(
  () => import(/* webpackChunkName: "case-categories" */ 'pages/case-categories'),
);

// sensitivities
const PageSensitivities = React.lazy(
  () => import(/* webpackChunkName: "sensitivities" */ 'pages/sensitivities'),
);

// active-ingredients
const PageActiveIngredients = React.lazy(
  () => import(/* webpackChunkName: "active-ingredients" */ 'pages/active-ingredients'),
);

// medical-conditions
const PageMedicalConditions = React.lazy(
  () => import(/* webpackChunkName: "medical-conditions" */ 'pages/medical-conditions'),
);

// dietary-preferences
const PageDietaryPreferences = React.lazy(
  () => import(/* webpackChunkName: "dietary-preferences" */ 'pages/dietary-preferences'),
);

// dosage-form-categories
const PageDosageFormCategories = React.lazy(
  () => import(/* webpackChunkName: "dosage-form-categories" */ 'pages/dosage-form-categories'),
);

// dosage-form-unit-types
const PageDosageFormUnitTypes = React.lazy(
  () => import(/* webpackChunkName: "dosage-form-unit-types" */ 'pages/dosage-form-unit-types'),
);

// dosage-forms
const PageDosageForms = React.lazy(
  () => import(/* webpackChunkName: "dosage-forms" */ 'pages/dosage-forms'),
);

// pharma-company-orders
const PageOrders = React.lazy(
  () => import(/* webpackChunkName: "pharma-company-orders" */ 'pages/pharma-company-orders'),
);

// SETTINGS
// settings languages
const PageLanguages = React.lazy(
  () => import(/* webpackChunkName: "languages" */ 'pages/languages'),
);

// settings pro-users
const PageProUsers = React.lazy(
  () => import(/* webpackChunkName: "pro-users" */ 'pages/crm-profiles'),
);

// settings pro-users-item
const PageProUserItem = React.lazy(
  () => import(/* webpackChunkName: "user-profile" */ 'pages/crm-profile-item'),
);

// settings permissions
const PagePermissions = React.lazy(
  () => import(/* webpackChunkName: "permissions" */ 'pages/crm-permissions'),
);

// settings redis-params
const PageRedisParams = React.lazy(
  () => import(/* webpackChunkName: "redis-params" */ 'pages/redis-params'),
);

const PageLabelsForBot = React.lazy(
  () => import(/* webpackChunkName: "labels-for-bot" */ 'pages/labels-for-bot'),
);

const PageLabelsForPlatform = React.lazy(
  () => import(/* webpackChunkName: "labels-for-platform" */ 'pages/labels-for-platform'),
);

const PageLabelsForCRM = React.lazy(
  () => import(/* webpackChunkName: "labels-for-platform" */ 'pages/labels-for-crm'),
);

const PageLabelsForPortal = React.lazy(
  () => import(/* webpackChunkName: "labels-for-platform" */ 'pages/labels-for-portal'),
);

const PageLabelsForWebsite = React.lazy(
  () => import(/* webpackChunkName: "labels-for-platform" */ 'pages/labels-for-website'),
);

// pro-logs
const PageProLogs = React.lazy(() => import(/* webpackChunkName: "pro-logs" */ 'pages/pro-logs'));

// customer-portal-logs
const PageCustomerPortalLogs = React.lazy(
  () => import(/* webpackChunkName: "customer-portal-logs" */ 'pages/customer-portal-logs'),
);

// cms-logs
const PageCmsLogs = React.lazy(() => import(/* webpackChunkName: "cms-logs" */ 'pages/cms-logs'));

// rest
const HomePage = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages/home'));
const LoginPage = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages/login'));
const LoginCodePage = React.lazy(() => import(/* webpackChunkName: "home" */ 'pages/login-code'));

const NotFoundPage = React.lazy(
  () => import(/* webpackChunkName: "not-found" */ 'pages/not-found'),
);

const DevPage = IS_DEV_BUILD
  ? React.lazy(() => import(/* webpackChunkName: "development" */ 'pages/__dev-test'))
  : null;

export const routes = createBrowserRouter([
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageProfile />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageDashboard />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PRODUCTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageProducts />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.TENANTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageTenants />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CASE_PAGES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageFlowEditor />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.FLOW_EDITOR_ITEM.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageFlowEditorItem />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PHARMA_COMPANIES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PagePharmaCompanies />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.BRANDS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageBrands />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.COUNTRIES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageCountries />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.GENDERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageGenders />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ORDER_STATUSES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageOrderStatuses />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CASE_CATEGORIES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageCaseCategories />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SENSITIVITIES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageSensitivities />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ACTIVE_INGREDIENTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageActiveIngredients />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.MEDICAL_CONDITIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageMedicalConditions />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.DIETARY_PREFERENCES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageDietaryPreferences />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.DOSAGE_FORM_CATEGORIES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageDosageFormCategories />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.DOSAGE_FORMS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageDosageForms />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.DOSAGE_FORM_UNIT_TYPES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageDosageFormUnitTypes />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ORDERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageOrders />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SETTINGS.path,
        children: [
          {
            path: APP_ROUTES.SETTINGS_LANGUAGES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageLanguages />
              </React.Suspense>
            ),
          },
          // {
          //   path: APP_ROUTES.SETTINGS_LABELS.path,
          //   element: (
          //     <React.Suspense fallback={<AppLoading />}>
          //       <PageLabels />
          //     </React.Suspense>
          //   ),
          // },
          {
            path: APP_ROUTES.SETTINGS_PERMISSIONS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PagePermissions />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SETTINGS_REDIS_PARAMS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageRedisParams />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SETTINGS_PRO_USERS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageProUsers />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SETTINGS_PRO_USER_ITEM.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <PageProUserItem />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.SETTINGS_LABELS.path,
            element: <LabelsLayout />,
            children: [
              {
                index: true,
                element: (
                  <React.Suspense fallback={<AppLoading />}>
                    <PageLabelsForCRM />
                  </React.Suspense>
                ),
              },
              {
                path: APP_ROUTES.SETTINGS_LABELS_FOR_BOT.path,
                element: (
                  <React.Suspense fallback={<AppLoading />}>
                    <PageLabelsForBot />
                  </React.Suspense>
                ),
              },
              {
                path: APP_ROUTES.SETTINGS_LABELS_FOR_PLATFORM.path,
                element: (
                  <React.Suspense fallback={<AppLoading />}>
                    <PageLabelsForPlatform />
                  </React.Suspense>
                ),
              },
              {
                path: APP_ROUTES.SETTINGS_LABELS_FOR_PORTAL.path,
                element: (
                  <React.Suspense fallback={<AppLoading />}>
                    <PageLabelsForPortal />
                  </React.Suspense>
                ),
              },
              {
                path: APP_ROUTES.SETTINGS_LABELS_FOR_WEBSITE.path,
                element: (
                  <React.Suspense fallback={<AppLoading />}>
                    <PageLabelsForWebsite />
                  </React.Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        path: APP_ROUTES.PRO_LOGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageProLogs />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CUSTOMER_PORTAL_LOGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageCustomerPortalLogs />
          </React.Suspense>
        ),
      },

      {
        path: APP_ROUTES.CMS_LOGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PageCmsLogs />
          </React.Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: APP_ROUTES.HOME.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <HomePage />
          </React.Suspense>
        ),
      },
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
  ...(DevPage
    ? [
        {
          path: APP_ROUTES.__DEVELOPMENT_TEST.path,
          element: (
            <React.Suspense fallback={<AppLoading />}>
              <DevPage />
            </React.Suspense>
          ),
        },
      ]
    : []),
]);
