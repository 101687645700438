import React from 'react';

import style from './index.module.scss';

import {
  Box,
  BoxProps,
  Divider,
  LinearProgress,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { Panel } from 'layouts/private/components';

interface Classes {
  root: string;
}

interface Props extends Omit<BoxProps, 'title'> {
  className?: string;
  classes?: Partial<Classes>;
  title?: React.ReactNode;
  controlsSlot?: React.ReactNode;
  contentSlot?: React.ReactNode;
  isLoading?: boolean;
}

export const PageHeader: React.FC<Props> = ({
  className,
  title,
  children,
  classes,
  controlsSlot,
  contentSlot,
  isLoading,
  ...rest
}) => {
  const isTable = useMediaQuery<Theme>((theme) => theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  return (
    <>
      <Box className={clsx(style.root, classes?.root, className)} {...rest}>
        {isTable && <Panel isResponsive />}
        <Typography
          variant={isTable ? 'h4' : 'h2'}
          component={'h1'}
          textTransform={'capitalize'}
          minWidth={0}
          flexGrow={{ xs: 1, md: 0 }}
          overflow={'hidden'}
          textOverflow={'ellipsis'}
          whiteSpace={'nowrap'}
          ml={{ xs: -1, lg: 0 }}
        >
          {title}
        </Typography>
        <Box flex={{ xs: '0 0 auto', md: '1 0 auto' }}>{contentSlot}</Box>
        {!isMobile && children}
        {controlsSlot}
        <Divider orientation={'horizontal'} absolute />
        {isLoading && (
          <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }} />
        )}
      </Box>
      {isMobile && children}
    </>
  );
};
