import { DynamicOrder } from 'utils/dynamic';
import * as yup from 'yup';
import {
  IUserCrmProfile,
  IUserCrmProfileDynamicResponse,
  IUserCrmProfilePermission,
} from '__generated__/api';

export const schemaCrmUserProfile = yup.object({
  firstName: yup.string().required('rule-required').min(2, 'rule-min-length').default(''),
  lastName: yup.string().required('rule-required').min(2, 'rule-min-length').default(''),
  email: yup.string().nullable().required('rule-required').email('rule-email').default(''),
  userCrmProfilePermissionID: yup.string().nullable().required('rule-required').default(''),
  languageID: yup.string().nullable().required('rule-required').default(''),
  isActive: yup.boolean().required('rule-required').default(true),
  userPhoto: yup.mixed(),
  appIdentityUserID: yup.string().default(''),
});

export interface IGridCrmUsers
  extends Pick<
    IUserCrmProfile,
    | 'id'
    | 'appIdentityUserID'
    | 'userPhoto'
    | 'fullName'
    | 'userCrmProfilePermissionID'
    | 'email'
    | 'isActive'
  > {
  position: Pick<IUserCrmProfilePermission, 'title'>;
}

export interface IGridCrmProfileArgs {
  search: string;
  userCrmProfilePermissionID: string;
  take: number;
  skip: number;
  orderBy: DynamicOrder;
}

export interface IUserCrmProfileInfo
  extends Pick<IUserCrmProfile, 'id' | 'fullName' | 'userPhoto'> {}

export interface IGridCrmProfileResponse extends Omit<IUserCrmProfileDynamicResponse, 'items'> {
  items: IGridCrmUsers[];
}

export interface CrmProfileInput extends yup.InferType<typeof schemaCrmUserProfile> {}

export interface ICrmUsersGridInput {
  search: string;
  userCrmProfilePermissionID: string;
  isActive: string | boolean | null;

  take?: number;
  skip?: number;
  orderBy: DynamicOrder;
}

export interface IGridCrmUsersResponse extends Omit<IUserCrmProfileDynamicResponse, 'items'> {
  items: IGridCrmUsers[];
}
