import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { IS_DEV } from 'configs/const';
import { PersistMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { all } from 'typed-redux-saga';
import { apiRtk } from 'utils/service';
import packages from '../../package.json';
import { reducerAccount, sagasAccounts } from './auth';
import { reducerCasePages } from './case-pages';
import { reducerCrmPermissions } from './crm-permissions';
import { reducerCrmUsers, sagasCrmUsers } from './crm-users';
import { reducerFlowEditor, sagasFlowEditor } from './flow-editor';

const VERSION = parseInt(packages.version.replace(/\./gi, ''));

const migrateStore: PersistMigrate = (state) => {
  if (VERSION === state?._persist.version) {
    return Promise.resolve(state);
  } else {
    return Promise.resolve(undefined);
  }
};

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  account: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'account',
      storage,
      whitelist: ['languageID', 'tenantID'],
    },
    reducerAccount,
  ),
  flowEditor: reducerFlowEditor,
  casePages: reducerCasePages,
  crmPermissions: reducerCrmPermissions,
  crmUsers: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'crmUsers',
      storage,
      whitelist: ['filters'],
    },
    reducerCrmUsers,
  ),
  [apiRtk.reducerPath]: apiRtk.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      //TODO https://github.com/reduxjs/redux-toolkit/issues/3950
      .concat(sagaMiddleware as any)
      .concat(apiRtk.middleware),
  devTools: IS_DEV,
});

export const persistor = persistStore(store);

function* rootSaga() {
  yield all([...sagasAccounts, ...sagasCrmUsers, ...sagasFlowEditor]);
}

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;
export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};
