import {
  AppConfigProvider,
  AppNotificationsProvider,
  AppThemeProvider,
  AuthProvider,
  AxiosInterceptorsProvider,
  DatePickerProvider,
  TranslateProvider,
} from 'contexts';

import { ErrorBoundary } from 'components/error-boundary';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { AppRoutes } from 'router';
import { persistor, store } from 'store';
import 'styles/app.global.scss';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ErrorBoundary>
          <AppConfigProvider>
            <AppThemeProvider>
              <AppNotificationsProvider>
                <AxiosInterceptorsProvider>
                  <TranslateProvider>
                    <AuthProvider>
                      <DatePickerProvider>
                        <DndProvider backend={HTML5Backend}>
                          <AppRoutes />
                        </DndProvider>
                      </DatePickerProvider>
                    </AuthProvider>
                  </TranslateProvider>
                </AxiosInterceptorsProvider>
              </AppNotificationsProvider>
            </AppThemeProvider>
          </AppConfigProvider>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  );
}

export default App;
