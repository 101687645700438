import React from 'react';

import style from './index.module.scss';

import { Box, BoxProps } from '@mui/material';
import clsx from 'clsx';

interface Props extends BoxProps {
  className?: string;
  children: React.ReactNode;
}

export const PageLayout: React.FC<Props> = ({ className, children, ...rest }) => {
  return (
    <Box className={clsx(style.root, className)} {...rest}>
      {children}
    </Box>
  );
};
