import {transformToCatalogName} from 'utils/transformers';
import * as yup from 'yup';
import {ICase, ICasePage} from '__generated__/api';
import {REGEXP_START_NUMBER} from '../../utils/regexp';
import {CASE_PAGE_TYPE} from '../case-page-types';

const sharedSchema = yup.object({
  catalogName: yup
    .string()
    .required('rule-required')
    .min(3, 'rule-min-length')
    .catalogName('rule-catalog-name')
    .matches(REGEXP_START_NUMBER, 'rule-start-number')
    .transform(transformToCatalogName)
    .default(''),
  delay: yup
    .number()
    .required('rule-required')
    .integer('rule-integer')
    .test('is-positive', 'rule-positive', (value) => value >= 0)
    .default(0),
  genderID: yup.string().required('rule-required').default(''),
  fromAge: yup.number().required('rule-required').default(0),
  toAge: yup.number().required('rule-required').default(0),
  autoSkip: yup.boolean().default(false),
  required: yup.boolean().default(false),
});

const defaultFields: Array<'catalogName' | 'genderID' | 'fromAge' | 'toAge' | 'delay'> = ['catalogName', 'genderID', 'fromAge', 'toAge', 'delay'];

type Options = {
  useForVitAssist: boolean;
};
export const factorySchemaCasePage = (type: CASE_PAGE_TYPE, options: Options) => {
  const {useForVitAssist} = options;
  switch (type) {
    case CASE_PAGE_TYPE.RedFlag:
      return sharedSchema.pick(defaultFields);
    case CASE_PAGE_TYPE.Sensitivity:
      return sharedSchema.pick([...defaultFields, 'autoSkip']);
    case CASE_PAGE_TYPE.DosageForm:
      return sharedSchema.pick([...defaultFields, useForVitAssist ? 'autoSkip' : 'required']);
    case CASE_PAGE_TYPE.Symptom:
    case CASE_PAGE_TYPE.Message:
    case CASE_PAGE_TYPE.Decision:
    case CASE_PAGE_TYPE.DietaryPreference:
    case CASE_PAGE_TYPE.ComputeMedicalCondition:
      return sharedSchema.pick(defaultFields);
    case CASE_PAGE_TYPE.Start:
    case CASE_PAGE_TYPE.End:
    case CASE_PAGE_TYPE.Stop:
      return sharedSchema.pick(['catalogName', 'delay']);
    case CASE_PAGE_TYPE.MedicalCondition:
    case CASE_PAGE_TYPE.MedicalConditionGroup:
      return sharedSchema.pick([...defaultFields, 'required']);
  }
};

export interface GetCasePageFormResponse
  extends Pick<
    ICasePage,
    'id' | 'catalogName' | 'delay' | 'genderID' | 'fromAge' | 'toAge' | 'autoSkip' | 'required'
  > {
}

export interface CasePageNavigation extends Pick<ICasePage, 'id' | 'catalogName' | 'case'> {
  case: Pick<ICase, 'id' | 'catalogName'>;
}
