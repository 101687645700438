import { format } from 'date-fns';
import { convertToDate, DateValue } from 'utils/dates';

export const setToModel = <Model extends { [x: string]: any }>(
  model: Model,
  ...args: Partial<Model>[]
) => {
  const argsRevers = args.filter(Boolean).reverse();
  const keys = Object.keys(model);
  return keys.reduce<Partial<Model>>((acc, key: keyof Model) => {
    const targetValue: Partial<Model> | undefined = argsRevers.find(
      (item) => item.hasOwnProperty(key) && item[key] !== '' && item[key] !== null,
    );
    acc[key] = targetValue ? targetValue[key] : model[key];
    return acc;
  }, {}) as Model;
};
export const keepToModel = <Model extends { [x: string]: any }>(
  model: Model,
  arg: Partial<Model>,
) => {
  const argsRevers = [arg].filter(Boolean).reverse();
  const keys = Object.keys(model);
  return keys.reduce<Partial<Model>>(
    (acc, key: keyof Model) => {
      const targetValue: Partial<Model> | undefined = argsRevers.find((item) =>
        item.hasOwnProperty(key),
      );
      if (targetValue) {
        acc[key] = targetValue[key];
      }
      return acc;
    },
    {} as typeof arg,
  );
};
const dec2hex = (dec: number) => {
  return dec.toString(16).padStart(2, '0');
};
export type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any ? A : never;
export const getRandomString = (length = 10) => {
  const arr = new Uint8Array((length || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

export function pick<T extends Record<string, any>, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Pick<T, K> {
  return Object.entries(obj)
    .filter(([key]) => keys.includes(key as K))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {} as Pick<T, K>);
}

export const omit = <T extends Record<string, any>, K extends keyof T>(
  obj: T,
  ...keys: K[]
): Omit<T, K> =>
  Object.entries(obj)
    .filter(([key]) => !keys.includes(key as K))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {} as Omit<T, K>);

export const createMap = <M extends Record<string, any>>(source: M[], key: keyof M) => {
  return source.reduce(
    (acc, item) => {
      acc[item[key]] = item;
      return acc;
    },
    {} as Record<string, M | undefined>,
  );
};

export const throttle = <T extends Function>(callback: T, limit: number) => {
  let waiting = false;
  let latestArguments: Array<any> | null = null;
  let latestContext: any | null = null;

  function wrapper(...args: any[]) {
    if (waiting) {
      latestArguments = args;
      // @ts-ignore
      latestContext = this;
      return;
    }

    // @ts-ignore
    callback.apply(this, args);

    waiting = true;

    setTimeout(function () {
      waiting = false;

      if (latestArguments) {
        callback.apply(latestContext, latestArguments);

        latestArguments = null;
        latestContext = null;
      }
    }, limit);
  }

  return wrapper;
};

export const debounce = <T extends Function>(cb: T, delay: number) => {
  let timerID: NodeJS.Timeout | null = null;
  return (...args: ArgumentTypes<T>) => {
    if (timerID) {
      clearTimeout(timerID);
    }
    timerID = setTimeout(() => {
      cb(...args);
      timerID = null;
    }, delay);
  };
};
export const getUrlExtension = (str: string) => {
  return str.split('.').pop()?.trim();
};
export const bytesToMB = (value: number | string) => {
  return (Number(value) / 1024 / 1024).toFixed(2);
};
const replaceTranslate = (value: string, replacer: (v: string) => string) => {
  return String(value).replace(/{{[a-zA-Z]+?}}/g, (v: string) => {
    const key = v.substring(2, v.length - 2);
    return replacer(key);
  });
};
export const calcTranslate = (value: string, payload: { [x: string]: any } = {}) => {
  return replaceTranslate(value, (key) => {
    return payload[key] === undefined ? '-- --' : payload[key];
  });
};

export const fieldToLabelKey = <T extends Record<string, any> = any>(field: keyof T) => {
  return String(field)
    .replace(/ID/g, '')
    .replace(/[A-Z]+/g, (substring) => {
      return `-${substring}`;
    })
    .toLowerCase()
    .replace(/^-/gi, '');
};

type AnyFunction = (...args: any[]) => any;
export const composeFunctions = <T extends (...args: any[]) => any>(
  cb: T,
  ...functions: (AnyFunction | undefined)[]
) => {
  return (...args: any[]) => {
    const result = cb(...args);
    (functions.filter(Boolean) as AnyFunction[]).forEach((fn) => {
      fn(...args);
    });
    return result;
  };
};

export const replaceSpace = (value: string) => value.replace(/ /g, '');
export const transformToLabelKey = (value: string) => fieldToLabelKey(value);
export const decodeValue = (value: string | null | undefined) =>
  value ? decodeURIComponent(value) : value;

interface Options {
  x: number;
  y0: number;
  y1: number;
  x0: number;
  x1: number;
}
export const interpolation = (options: Options) => {
  const { x, y1, y0, x0, x1 } = options;
  return y0 + ((y1 - y0) / (x1 - x0)) * (x - x0);
};

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const makeDateKey = <T extends { date: DateValue }>(
  dataItem: T,
  dateFormat = 'yyyy-MM-dd',
) => {
  return format(convertToDate(dataItem.date), dateFormat);
};
